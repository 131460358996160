.documents {
  .documentName {
    font-size: 18px;
    margin-bottom: 0;
  }
  .documentDate {
  }
  .documentDownloadBtn {
    width: 120px;
  }
}
.documentsMoving, .leverage {
  width: 1000px;
  margin: 20px auto;

  .nav-tabs {
    margin-bottom: 0;
    border-bottom: none;
    position: relative;
  }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    border: none;
    box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.05);
  }
  .tab-content {
    background: white;
    padding: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
    padding: 0 !important;
    border-radius: 6px;
  }
  .tab-content .open-inst {
    background-color: #e6e8fa;
  }
  .boardsTabs {
    .boardsTabs-title {
      color: #2e3c47;
      font-weight: 500;
      margin-bottom: -2px;
    }
  }
  .nav-tabs .navItem-empty .nav-link {
    cursor: default;
  }
  .navItem-empty div {
    color: #2e3c47;
    opacity: 0.6;
  }
  .nav-tabs .nav-link {
    border: none;
    cursor: pointer;
    height: 100% !important;
    padding: 12px 0 14px !important;
    color: #2e3c47 !important;
  }
  .nav-tabs .nav-link.active {
    background-color: #fff !important;
    border-radius: 6px 6px 0 0;
  }
  .nav-tabs .emptyTab {
    opacity: 0.6;
    cursor: default;
  }

  .nav-tabs .nav-item {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: -5px;
    width: 140px;
  }

  .closeDealsBtns {
    position: absolute;
    top: 6px;
    right: 0;

    .btn {
      margin-left: 20px;
      height: 30px;
      line-height: 28px;
      padding: 0 24px !important;
      font-size: 14px;
      font-weight: 600;
      font-family: 'Montserrat', sans-serif !important;
    }
  }
  .docTab-sign {
    position: relative;
  }
  .docTab-signText {
    position: absolute;
    top: -11px;
    right: -11px;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    font-size: 9px;
    font-weight: 500;
    border-radius: 50%;
    color: white;
    background: #5aad5f;
  }

  .docCreate {
    width: 10%;
  }
  .docCur {
    width: 10%;
  }
  .docDescr {
    width: 40%;
  }
  .docComm {
    width: 30%;
    padding-right: 22px;
  }
  .docStatus {
    width: 20%;
    position: relative;
    padding-right: 34px;}
  &.withdrawAssets {
    .docDescr {
      width: 27%;
    }
    .docComm {
      width: 22%;
    }
    .docStatus {
      width: 16%;
  
    }
  }
  .docDescr-title {
    font-size: 16px;
    font-weight: 500;
  }
  .docDescr-title a {
    color: #2e3c47;
  }
  .docDescr-text {
    font-size: 10px;
    opacity: 0.6;
  }
  .docComm span {
    opacity: 0.6;
    line-height: 20px;
    overflow-y: hidden;
    max-height: 36px;
    display: inline-block;
  }
  .docComm a {
    font-size: 14px;
    display: inline-block;
  }
  tbody .docStatus:after {
    position: absolute;
    content: '';
    top: 50%;
    right: 18px;
    margin-top: -4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #5672db;
  }
  tbody .docStatus.docStatus-success:after {
    background: #5aad5f;
  }
  tbody .docStatus.docStatus-reject:after {
    background: #d06161;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }
  .react-datepicker__input-container {
    input {
      width: 100%;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 3px;
    }
  }

  .justifiedBtnGroup {
    display: flex !important;
  }
  .justifiedBtnGroup label {
    flex: 1 !important;
  }
  .btn-group-toggle > .btn input[type='checkbox'],
  .btn-group-toggle > .btn input[type='radio'],
  .btn-group-toggle > .btn-group > .btn input[type='checkbox'],
  .btn-group-toggle > .btn-group > .btn input[type='radio'] {
    clip: none !important;
  }

  .profileDocs-uploaderBtn {
    display: inline-block;
  }
  .documentsMoving-content {
    padding-top: 15px;
  }
  .createDoc-container {
    padding: 25px 0;
    text-align: center;
    border-top: 2px solid #ccc;
    border-bottom: 2px solid #ccc;
    margin-bottom: 30px;
    margin-left: -20px;
    margin-right: -20px;
    cursor: pointer;
  }
  .nav-tabs-solid .nav-link.active,
  .nav-tabs-solid .nav-item.show .nav-link.active {
    color: #fff;
    background-color: #2196f3;
    border: none;
    border-radius: 0;
  }
  table tr td {
    vertical-align: middle;
    font-size: 12px !important;
    font-weight: 500 !important;
  }
  table tr:nth-child(2n) {
    background: #f7f8fc;
  }
  .docAct-time {
    font-size: 18px;
    width: 132px;
  }
  .docAct-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0;
    a {
      color: black;
    }
    a:hover {
      text-decoration: underline;
    }
  }
  .docAct-title a {
    color: #333;
  }
  .docAct-status {
    width: 230px;
  }

  .emptyDashboard-plug {
    position: relative;
    height: calc(100vh - 158px);
    .emptyData {
      top: calc(50% + 15px);
    }
  }
  .emptyDashboard-plug:before {
    content: '';
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    height: 10px;
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }

  .clipper,
  .scroller {
    position: relative;
    height: calc(100vh - 200px);
    max-height: none !important;
  }
  .scroller {
    position: relative;
    box-shadow: none;
    border-radius: 0 0 6px 6px;
  }
  .clipper:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    z-index: 5;
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }

  .docsTable tr.updatedDoc:nth-child(2n + 1) {
    animation-duration: 5s;
    animation-name: updatedDocOdd;
  }
  .docsTable tr.updatedDoc:nth-child(2n) {
    animation-duration: 5s;
    animation-name: updatedDocEven;
  }
  @keyframes updatedDocOdd {
    from {
      background-color: #e6e8fa;
    }
    to {
      background-color: #ffffff;
    }
  }
  @keyframes updatedDocEven {
    from {
      background-color: #e6e8fa;
    }
    to {
      background-color: #f7f8fc;
    }
  }
}

.documentPage {
  .card {
    padding: 28px 32px !important;
  }
  .card-content {
    width: 500px;
    position: relative;
  }
  .card-header,
  .card-body {
    padding: 0 !important;
  }

  .docNeedScan-links {
    margin-bottom: 20px;
  }
  .docNeedScan-links a {
    min-width: 125px;
    padding: 0 !important;
  }
  .docNeedScan-links .btn {
    padding: 0 !important;
  }
  .profileDocs-uploaderBtn {
    display: inline-block;
  }
  .docNeedScan-upload a {
    cursor: pointer;
    display: inline-block;
  }
  .docNeedScan-upload {
    position: relative;
    padding-top: 38px;
  }
  .docNeedScan-uploadBtn {
    position: absolute;
    top: 0;
    left: 0;
  }

  .docNeedScan-uploadFileList {
    margin-bottom: 8px;

    a {
      font-size: 12px;
      letter-spacing: 0.06px;
      opacity: 0.8;
      color: #2e3c47;
    }
    .docRemoveFile {
      font-size: 10px;
      color: #d06161;
      margin-bottom: -2px;
    }
  }

  .profileDocs-uploaderCheck {
    font-size: 30px;
  }
  .cancelTypeDoc-icon {
    font-size: 65px;
  }

  .docPage-title {
    font-size: 16px;
    letter-spacing: 0.08px;
    font-weight: 600;
    opacity: 0.8;
    margin-bottom: 2px;
  }
  .docPage-subtitle {
    font-size: 9px;
    letter-spacing: 0;
    opacity: 0.6;
    margin-bottom: 20px;
  }
  .docPage-uploadDisc {
    font-size: 12px;
    letter-spacing: 0.06px;
    opacity: 0.8;
    margin-bottom: 8px;
  }
  .docPage-doneContent .btn {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .documentPage-content {
    position: relative;
    padding-right: 16px;
  }

  .clipper,
  .scroller {
    max-height: none !important;
    height: calc(100vh - 220px);
  }
  .clipper {
    position: static;
  }

  .btnSubmits {
    padding: 0 20px;
    position: absolute;
    bottom: -80px;
    left: -32px;

    .btn {
      line-height: 28px;
      padding: 0 25px;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .docPage-progressSteps {
    position: absolute;
    top: 0;
    left: calc(100% + 48px);
    width: 100%;

    div {
      margin-bottom: 10px;
      font-size: 12px;
      letter-spacing: 0.06px;
      opacity: 0.4;
    }
    .currentDocStatus {
      opacity: 1;
    }
  }

  .docPage-needLetter {
    .inputUnderLine {
      width: 130px;
      font-size: 12px;
      letter-spacing: 0.06;
    }
    .inputUnderLine::placeholder {
      opacity: 0.4;
    }
  }
  .docPage-needSms {
    .inputUnderLine {
      width: 130px;
      font-size: 12px;
      letter-spacing: 0.06;
    }
    .inputUnderLine::placeholder {
      opacity: 0.4;
    }
  }
}

.createDoc {
  .docTypeBtns button {
    width: 100%;
  }
}

.documentCreatePage {
  position: relative;

  .documentCreatePage-modal {
    position: absolute;
    z-index: 1050;
    top: 50%;
    left: 45%;
    right: 0;
    transform: translate(-50%, -50%);
    width: 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    background-color: rgb(133, 133, 133);
    color: #fff;

    & > .btn {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 30px;
      margin-top: 20px;
    }
  }
  .card-content {
    width: 500px;
  }
  .card-body {
    padding: 25px 32px;
  }

  .btnLink {
    width: auto !important;
    padding: 0 25px !important;
  }

  .docCreateChoice {
    padding-bottom: 10px;
  }
  .docCreateChoice-title {
    font-size: 10px;
    opacity: 0.6;
  }
  .docCreateChoice-select .customSelect {
    width: 250px;
    height: 25px;

    .customSelectedItem {
      background: transparent;
      padding: 0 16px 0 0;
      line-height: 20px;
      font-size: 14px;
      font-weight: 500;
      color: #2e3c47;
      border-bottom: 1px solid #5672db;
      border-radius: 0;
    }
    ul {
      border-top: none;
      top: 100%;
      background: white;

      li {
        padding: 0 16px 0 0;
        line-height: 25px;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .toggle {
      right: 0;
      top: 5px;
      font-size: 18px;
      color: #5672db;
    }
  }

  .checkboxContainer {
    font-size: 9px;
    padding-top: 0;
    letter-spacing: 0.05px;
    padding-left: 17px;
    margin-bottom: 8px !important;

    .checkmark {
      top: 0px;
      width: 12px;
      height: 12px;
    }
    .checkmark:after {
      left: 3px;
      top: 0px;
      width: 5px;
      height: 8px;
    }
  }

  .docCreateContent {
    padding-top: 15px;

    .SingleDatePicker_picker {
      position: fixed !important;
      top: 50% !important;
      left: 50% !important;
      margin-top: -150px;
      z-index: 11;
    }

    .contractNameDocCancel {
      font-size: 12px;
      opacity: 0.8;
      margin-bottom: 25px;
    }
    .dateDocCancel {
      // position: relative;
      position: static;
    }
    .dateDocCancel .dateDocCancel-placeholder {
      position: absolute;
      top: 8px;
      left: 0;
      opacity: 0.8;
      font-size: 12px;
      z-index: 2;
      pointer-events: none;
    }
    .react-datepicker__input-container input {
      padding: 7px 9px !important;
      border: none;
      background-color: #f7f7fc;
      color: #2e3c47 !important;
      font-size: 12px;
      outline: none;
      border-radius: 4px;
    }
    .react-datepicker__input-container .dateInputValidateWrong {
      border: none !important;
      border-bottom: 1px solid #d06161 !important;
    }
    .react-datepicker__input-container input:focus {
      border-color: #5672db !important;
      outline: none;
    }
    input:disabled {
      color: #2e3c47 !important;
    }

    .clipper,
    .scroller {
      max-height: none !important;
      height: calc(100vh - 300px);
    }
    .clipper {
      position: static;
    }
    .scroller {
      overflow-x: hidden;
    }
    .track {
      top: 75px;
      bottom: 25px;
    }

    &.readyStep {
      & .scroller {
        padding-left: 30px;
      }
    }
  }
  .docCreateAnketa {
    .anketaChange-subtitleContainer {
      margin-bottom: 20px;
    }
    .anketaChange-subtitle {
      font-size: 16px;
      opacity: 0.8;
      letter-spacing: 0.08px;
      font-weight: 600;
    }
    .addressHandWriting {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.06px;
      color: #5672db;

      .addressHandWriting-reset {
        margin-right: 22px;
      }
    }

    .profile-fioField {
      width: 220px !important;
    }

    .react-datepicker__input-container input {
      width: 100% !important;
      opacity: 1;
    }
    .react-datepicker__input-container input:disabled {
      opacity: 0.4;
    }

    .docCreate-dateLabel {
      font-size: 12px;
      font-weight: normal;
      padding-right: 0 !important;
      padding-top: 7px !important;
      // opacity: 0.4;
    }

    .docCreate-anketaExtraFlType {
      margin-bottom: 25px;
      font-size: 12px;
      opacity: 0.4;
      letter-spacing: 0.06px;
    }
    .docCreate-anketaAddress-placeSubtitle {
      margin-bottom: 20px;
      font-size: 12px;
      font-weight: 500;
      opacity: 0.4;
    }
    .uniform-uploader input[type='file'] {
      width: 100% !important;
    }
  }
  .docCreatePayment {
    .customSelect-payAccContainer {
      margin-bottom: 30px;
    }
    .customSelect-payAccContainer span {
      font-size: 12px;
    }
    .customSelect {
      width: auto;
      margin-left: 22px;
      display: inline-block;

      .customSelectedItem {
        padding: 0 34px 0 16px;
        font-size: 12px;
        opacity: 0.8;
        line-height: 30px;
      }
      ul {
        font-size: 12px;
        opacity: 0.8;
      }
      ul li {
        line-height: 30px;
      }
      .toggle {
        top: 6px;
        font-size: 16px;
      }
    }

    .docCreatePayment-sumField {
      background: white;
      padding: 5px 5px 5px 0;
      line-height: 15px;
      font-size: 12px;
      border-bottom: 1px solid #f2f3fa !important;
      outline: none;
    }
    .docCreatePayment-sumDescr {
      margin-top: 3px;
      font-size: 10px;
      opacity: 0.6;
    }
  }
  .docCreateTaxpayer {
    .SingleDatePicker_picker {
      z-index: 11;
    }
    .contractNameDocCancel {
      margin-bottom: 30px;
    }
    .docCreateTaxpayerContent-fields {
      display: inline-block;
      width: 215px;
      border-right: 1px solid #f2f3fa;
      padding: 10px 0;
    }
    .docCreateTaxpayerContent-fields,
    .docCreateTaxpayerContent-uploads {
      display: inline-block;
      width: 215px;
      vertical-align: top;
    }
    .docCreateTaxpayerContent-fields input {
      margin-bottom: 20px;
      width: 160px;
    }
    .docCreateTaxpayerContent-uploads {
      padding-left: 44px;
    }
    .docCreateTaxpayerContent-uploadsTitle {
      opacity: 0.6;
      margin-bottom: 4px;
      font-size: 10px;
    }
    .docCreateTaxpayerContent-uploadOgrnip {
      margin-top: 30px;
      margin-bottom: 40px;
    }
    .docCreateTaxpayerContent-uploadsFile {
      height: 0;
      padding-top: 4px;
    }
  }

  .docCreateTaxpayer-individual input {
    margin-bottom: 20px;
    width: 100%;
  }
  .docCreateTaxpayer-individual .react-datepicker__input-container input {
    width: 100%;
  }

  .btnSubmits {
    padding: 0 20px;
    position: absolute;
    bottom: -55px;
    left: 0;

    .btn {
      line-height: 28px;
      padding: 0 25px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .inputFields-left {
    padding-right: 38px !important;
  }
  .inputFields-right {
    padding-left: 33px !important;
  }
  @media screen and (max-width: 992px) {
    .inputFields-left {
      padding-right: 10px !important;
    }
    .inputFields-right {
      padding-left: 10px !important;
    }
  }

  .formField-container {
    margin-bottom: 25px;

    input,
    input[readonly],
    input:focus,
    input[readonly]:focus {
      height: 26px;
      width: 100%;
    }

    input[readonly],
    input[readonly]:focus {
      opacity: 0.4;
    }
  }
}

.leverage {
  .card-content {
    position: relative;
    width: auto;
  }
  .scroller, .clipper {
    height: auto;
    max-width: 700px;
  }
  .table {
    width: 700px;
  }
  tbody tr {
    cursor: pointer;
  }
  .leverageFormContainer {
    position: sticky;
    top: 10px;

    & > div {
      padding-bottom: 50px;
    }

    h2 {
      padding-top: 10px;
    }
  }
  .leverageForm {
    display: flex;
    justify-content: center;
    align-items: stretch;
    margin-top: 18px;
    padding-bottom: 50px;

    button {
      cursor: pointer;
      height: 36px;
      width: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 25px;
      font-weight: bold;
      border: 1px;
    }

    input {
      flex: 1;
      padding: 0 10px;
    }
  }
  .spinner-container {
    left: auto;
  }
}

.inputValidateWrong.anketaReadOnlyFields {
  border-color: #d06161 !important;
}

.inputValidateWrong.anketaReadOnlyFields.inputUnderLine[readonly]:focus {
  border-color: #d06161 !important;
}