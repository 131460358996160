.appFilesPage {
  .card-content {
    width: 980px;
  }
  .title {
    font-size: 20px;
    letter-spacing: 0.23px;
    opacity: 1;
  }
  .appFiles-list {
    .appFiles-listOne {
      margin-bottom: 44px;
      position: relative;
    }
    .appFiles-logo {
      display: inline-block;
      vertical-align: top;
      width: 112px;
    }
    .appFiles-version {
      display: inline-block;
      vertical-align: top;
      width: 232px;
      font-weight: normal;
    }
    .appFiles-descriptionContainer {
      display: inline-block;
      vertical-align: top;
      width: 630px;
      position: relative;
    }
    .appFiles-description {
      max-height: 120px;
      overflow-y: hidden;
    }
    .appFiles-description.appFiles-descMore {
      max-height: none;
    }
  }
  .appFiles-logoContainer {
    display: inline-block;
    width: 80px;

    .appFiles-logoBlock {
      position: relative;
      width: 100%;
      height: 80px;
      border-radius: 10px;
      box-shadow: -1px 1px 14px 2px rgba(12, 34, 61, 0.07),
        -1px 1px 3px 0 rgba(14, 41, 65, 0.12);
      background: #ffffff;

      svg {
        position: absolute;
        top: 17px;
        left: 22px;
        fill: url(#linear-gradient);
      }

      img {
        width: 49px;
        height: 54px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .appFiles-logoDownload {
      margin-top: 8px;

      a {
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.17px;
        color: #1e62d6;
      }
    }
  }
  .appFiles-versionName {
    font-size: 17px;
    letter-spacing: 0.2px;
  }
  .appFiles-versionDate {
    font-size: 17px;
    letter-spacing: 0.2px;
    color: #6f7982;
  }
  .appFiles-description {
    ul li {
      position: relative;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0.14px;
      margin-bottom: 5px;
      list-style: none;
    }
    ul li:before {
      position: absolute;
      top: 0;
      left: -10px;
      content: '-';
    }
  }
  .appFiles-descriptionMore {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.14px;
    text-align: right;
    position: absolute;
    bottom: -20px;
    right: 0;
    color: #6f7982;

    span {
      cursor: pointer;
    }
  }
}
