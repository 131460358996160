.CalendarDay--selected-span {
  background: #7088e4 !important;
  border: 1px double rgba(255, 255, 255, 0.14) !important;
}
.CalendarDay--selected-start,
.CalendarDay--selected-end,
.CalendarDay--selected-start:active,
.CalendarDay--selected-end:active,
.CalendarDay--selected:active {
  background: #5672db !important;
  border: 1px double rgba(255, 255, 255, 0.14) !important;
}

.DateInput--open-down.DateInput--with-caret::before {
  display: none;
}
.DateInput--open-down.DateInput--with-caret::after {
  display: none;
}
.DayPicker--horizontal {
  box-shadow: -3px 3px 21px 0 rgba(35, 68, 106, 0.21) !important;
  border-radius: 8px !important;
}
.DayPicker__week-header {
  top: 55px !important;
}
.DayPicker__week-header li small {
  font-size: 9px;
  letter-spacing: 0.12px;
  font-weight: 700;
  color: #2e3c47;
  text-transform: uppercase;
}
.CalendarDay {
  border: solid 1px #f2f3fa !important;
}
.DayPicker table tbody tr:nth-child(2n) {
  background: white !important;
}
.CalendarMonth__caption strong {
  font-weight: 600;
  font-size: 14px !important;
  letter-spacing: 0.19px;
  text-transform: uppercase;
}
.DayPickerNavigation--horizontal .DayPickerNavigation__prev svg,
.DayPickerNavigation--horizontal .DayPickerNavigation__next svg {
  display: none;
}
.DayPickerNavigation--horizontal .DayPickerNavigation__prev,
.DayPickerNavigation--horizontal .DayPickerNavigation__next {
  width: 32px;
  height: 32px;
  border: none;
  outline: none;
}
.DayPickerNavigation--horizontal .DayPickerNavigation__prev:hover,
.DayPickerNavigation--horizontal .DayPickerNavigation__next:hover {
  border: none !important;
}
.DayPickerNavigation--horizontal .DayPickerNavigation__prev {
  background: url('/images/ui/calendar-prev.png') no-repeat;
}
.DayPickerNavigation--horizontal .DayPickerNavigation__next {
  background: url('/images/ui/calendar-next.png') no-repeat;
}
.CalendarDay--hovered-span,
.CalendarDay--after-hovered-start {
  background: #7088e4 !important;
  color: white !important;
}
.CalendarDay--hovered-span:active,
.CalendarDay--after-hovered-start:active {
  background: #5672db !important;
  color: white !important;
}
.CalendarDay--selected-start.CalendarDay--hovered-span {
  background: #5672db !important;
  color: white !important;
}
.CalendarDay--selected-span.CalendarDay--hovered {
  background: #5672db !important;
}
