body .table-shopOffer thead td {
  border-top-width: 0 !important;
}
body .table-shopOffer tbody td {
  border-top: 1px solid #ddd !important;
}
.shop {
  .regSmsCode-options {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 0 6px;
    margin-bottom: 15px;

    span {
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 0.13px;
      color: #8a9299;
    }
    .regSmsCode-optionsSendAgain {
      color: #5672db;
      cursor: pointer;
    }
  }
}

.content-shopList {
  padding-left: 80px !important;
  padding-right: 80px !important;
  height: 100%;
  background: #f5f6fa;
}
.shopList {
  .title {
    margin-bottom: 8px;
  }
  .infinite-scroll-component {
    padding: 20px;
    margin-top: -12px;
  }
  .shopListTagsFilter {
    margin: 0 0 16px 0;
    div {
      display: inline-block;
      padding: 0 8px 0 10px;
      line-height: 24px;
      margin-right: 10px;
      border-radius: 12px;
      color: white;
      font-size: 10px;
      cursor: pointer;
      i {
        margin-top: 1px;
      }
    }
    .shopListTagFilter {
      background-color: #aaa;
    }
    .tag1 {
      background-color: #5672db;
    }
    .tag2 {
      background-color: #379f2e;
    }
    .tag3 {
      background-color: #d06161;
    }
  }
  .shopList-container {
    width: 300px;
    margin-right: 32px;
    float: left;
    margin-bottom: 20px !important;
  }
  .shopList-container .card-body {
    padding: 9px 14px 14px;
    height: 325px;
  }
  .shopList-image {
    position: relative;
    height: 150px;
    overflow: hidden;
    background-position: center left;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-color: white;
    border-radius: 6px 6px 0 0;
    box-shadow: -3px 3px 21px 0 rgba(137, 177, 223, 0.35) !important;
    cursor: pointer;
  }
  .shopList-playIcon {
    font-size: 34px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -20px;
    margin-top: -20px;
    color: white;
  }
  .card {
    border-radius: 0 0 6px 6px !important;
    margin-bottom: 0 !important;
  }
  .shopListTags {
    font-size: 10px;
    .shopListTag {
      color: #aaa;
      cursor: pointer;
      display: inline-block;
      margin-right: 8px;
    }
    .tag1 {
      color: #5672db;
    }
    .tag2 {
      color: #379f2e;
    }
    .tag3 {
      color: #d06161;
    }
  }
  .shopListContent {
    height: 204px;
    overflow: hidden;
  }
  .shopListTitle {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.2px;
    line-height: 1.38;
    color: #2e3c47;
    margin-top: 8px;
  }
  .shopListText {
    font-size: 10px;
    letter-spacing: 0.1px;
    margin-top: 12px;
    color: #2e3c47;
    height: 150px;
    overflow: hidden;
  }
  hr {
    border-top-color: rgba(0, 0, 0, 0.1);
    margin: 13px -16px;
  }
  .shopListInfo-name {
    font-size: 12px;
    height: 18px;
    font-weight: 500;
    max-height: 18px;
    overflow: hidden;
  }
  .shopListInfo-date {
    font-size: 10px;
    font-weight: 500;
  }
  .shopListInfo-link {
    font-size: 10px;
    height: 25px;
    margin-top: 22px;
  }
  .shopListInfo-linkText {
    position: relative;
    cursor: pointer;
    color: #4586d2;
    font-size: 10px;
    font-weight: 500;
    width: 160px;
    white-space: nowrap;
    overflow: hidden;
  }
  .shopListInfo-linkText::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 100%;
    background: -moz-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }
  .shopList-end {
    color: #d06161;
    font-size: 10px;
    font-weight: 500;
    height: 25px;
  }
  .shopListInfo-link a {
    padding: 3px;
    padding-left: 8px !important;
    padding-right: 8px !important;
    font-size: 14px;
    cursor: pointer;
    width: 100px;
  }
  .shopList-linkCopyBtn:hover {
    background: white;
    box-shadow: none !important;
  }

  .shopListInfoBlock {
    max-height: 72px;
    // overflow: hidden;
  }
  .shopList-time {
    font-size: 16px;
    font-weight: bold;
    color: #2196f3;
  }
  .shopList-name {
    font-size: 16px;
  }
  .shopList-eventName {
    font-weight: bold;
    margin-bottom: 0;
  }
  .shopList-eventDescription {}
  .shopLists-tag {
    margin-right: 10px;
  }
  .btnCell {
    width: 1px;
  }
  .spinner-container {
    clear: left;
  }
}
.content-shopConditions {
  max-width: 900px;
  margin: 0 auto;
}
.content-shopConditionsPersonal p {
  text-indent: 15px;
}
.content-shopConditionsPersonal ul {
  padding-left: 15px;
}
.table-shopContacts tbody tr:first-child td {
  border-top: none !important;
}

.shopContacts-link {
  position: fixed;
  top: 10px;
  right: 15px;

  a {
    color: #888;
  }
}
@media screen and (max-width: 992px) {
  .shopContacts-link {
    position: relative;
    top: auto;
    right: auto;
    text-align: center;
    margin-bottom: 15px;
  }
}

/* SHOP ITEM BUY */
.tinkoffPayRow {
  display: block;
  margin: 10px;
  width: 160px;
}

.shop-isAgreeContainer {
  .checkboxContainer {
    width: 18px;
    padding-left: 0px;
    display: inline-block;
    margin-bottom: 7px !important;
  }
  .checkmark {
    width: 12px;
    height: 12px;
  }
  .checkmark:after {
    left: 3px;
    top: 1px;
    width: 4px;
    height: 7px;
  }
  input {
    width: 0px;
  }
  .shop-agreeText {
    display: inline-block;
    font-size: 10px;
    font-weight: 300;
    vertical-align: top;
    margin-top: 2px;
  }
}
.shop-isAgreeContainer.formField-container {
  margin-bottom: 10px;
}
.btn.shopSmsCodeBtn {
  margin-top: 0 !important;
  margin-bottom: 15px;
}
.btn.shopBuyBtn {
  margin-top: 0 !important;
}

.shopBuyBtns {
  display: flex;
}

@media screen and (max-width: 800px) {
  .shopBuyBtns {
    display: block;

    & > div {
      margin-top: 10px;
    }
    img {
      display: block;
      margin-top: 10px;
    }
  }
}

body .btn.shopBuy-smsCodeBtn {
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 200px;
}



/* SERVEY */
.checkContainer.formField-container {
  margin-bottom: 10px;
}
.checkContainer .checkmark {top: 6px; border: 1px solid #9656fb;}
.checkContainer .checkboxContainer input:checked ~ .checkmark {background-color: #9656fb;}
.surveyCheckboxText {
  margin-left: 25px;
}
.requiredStar {
  color: #d93025;
}
.survey label {font-size: 14px; color: black; opacity: 1;}
.survey .subLabel {font-size: 12px; opacity: 0.8;}
.survey .popup_inner {left: 50%;}