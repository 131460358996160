.profileForm {
  .card-content {
    width: 580px;
  }
  .profileFormAlert {
    margin: -8px auto 20px;
    width: 580px;
    position: relative;
    border-radius: 4px;
    padding: 24px 36px;
    background-color: #fff;

    h3 {
      color: #2E3C47;
      font-size: 16px;
      line-height: 19.5px;
      font-weight: 600;
      margin: 0;
    }

    p {
      color: #58636C;
      font-size: 12px;
      line-height: 15px;
      font-weight: 400;
      margin: 8px 0 0 0;
      letter-spacing: 0.061875px;
    }
  }
  .profileFormAlert::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 6px;
    background: #5672DB;
    border-radius: 4px 0px 0px 4px;
  }
  .profileFormAlert.warn {
    background: #FDFFEE;

    h3 {
      margin-left: 9.5px;
    }
  }
  .profileFormAlert.warn::before {
    background: #DED15F;
  }
  .card.card-content {
    max-height: 454px;
  }
  .title {
    margin-bottom: 12px;
    font-size: 30px;
    font-weight: 800;
    letter-spacing: 0.35px;
    opacity: 0.9;
  }
  .card-body {
    padding: 24px !important;
  }

  .clipper,
  .scroller {
    max-height: none;
    height: 406px;
    overflow-x: hidden;
  }
  .card.trading {
    .clipper,
    .scroller {
      height: auto;
    }
  }
  .clipper {
    position: static;
  }
  .track {
    top: 25px;
    bottom: 25px;
  }

  .progressSteps {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: calc(100% + 24px);
    width: 100%;

    &.visible {
      visibility: visible;
    }

    div {
      margin-bottom: 10px;
      font-size: 12px;
      letter-spacing: 0.06px;
      opacity: 0.4;
    }
    .currentStep {
      opacity: 1;
    }
  }

  .inputFields-left {
    padding-right: 38px !important;
  }
  .inputFields-right {
    padding-left: 56px !important;
  }
  .inputDateValidateWrong {
    .DateInput_input {
      border-color: #d06161;
    }
  }
  .inputDateValidateCorrect {
    .DateInput_input {
      border-color: #5aad5f;
    }
  }
  .inputDateValidateFocused {
    .DateInput_input {
      border-color: #5672db;
    }
  }
  @media screen and (max-width: 992px) {
    .inputFields-left {
      padding-right: 10px !important;
    }
    .inputFields-right {
      padding-left: 10px !important;
    }
  }
  .subtitle {
    margin-bottom: 20px;
    opacity: 0.8;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.08px;
  }
  .subtitle-add {
    font-size: 12px;
    font-weight: 500;
    opacity: 0.8;
    letter-spacing: 0.06;
    margin-bottom: 20px;
  }

  .floating-label {
    left: 0;
  }

  .floating-label.country__select {
    top: -23px;
  }

  .profile-formFieldContainer {
    margin-bottom: 25px;

    input {
      font-size: 12px;
      font-family: Montserrat, sans-serif !important;
    }
    label {
      font-size: 12px;
      font-family: Montserrat, sans-serif !important;
    }
  }
  .formField-container {
    margin-bottom: 25px;

    input,
    input[readonly],
    input:focus,
    input[readonly]:focus {
      height: 28px;
      width: 100%;
      padding: 8px 5px 2px 0 !important;
    }
    .react-datepicker-wrapper {
      input,
      input[readonly],
      input:focus,
      input[readonly]:focus {
        padding: 7px 12px !important;
      }
    }
  }

  .profile-fioField {
    width: 220px !important;
  }
  .profile-codeCompanyField {
    width: 140px !important;
  }

  .form-group {
    margin-bottom: 24px !important;
  }

  .customSelect {
    font-size: 12px;
    font-family: Montserrat, sans-serif;
    height: 28px;
  }
  .customSelect .customSelectedItem {
    line-height: 28px;
    color: rgba(46, 60, 71, 0.8);
  }
  .customSelect ul li {
    line-height: 28px;
  }
  .customSelect .toggle {
    top: 6px;
    font-size: 16px;
  }

  .progressLineContainer {
    position: relative;
    text-align: center;
    margin: 20px auto 70px;
    width: 80%;
  }
  .progressLineContainer svg {
    display: inline-block;
  }
  .progressLine-iconContainer {
    position: absolute;
    top: -8px;
    left: 0;
    margin-left: -19px;
    width: 38px;
    background: #fff;
    text-align: center;
    color: #00bcd4;
    border-radius: 50%;
    border: 2px solid #00bcd4;
  }
  .progressLine-iconContainer i,
  .progressLine-iconContainer span {
    line-height: 34px;
    font-size: 20px;
  }
  .progressLine-iconContainer p {
    margin-bottom: 0;
    width: auto;
  }
  .progressLine-adressess {
    left: 25%;
  }
  .progressLine-addData {
    left: 50%;
  }
  .progressLine-bankData {
    left: 75%;
  }
  .progressLine-docs {
    left: 100%;
  }

  .progressLine-iconTitle {
    position: absolute;
    top: 40px;
    text-align: center;
    color: black;
  }
  .progressLine-personData .progressLine-iconTitle {
    left: -8px;
  }
  .progressLine-adressess .progressLine-iconTitle {
    left: -6px;
  }
  .progressLine-addData .progressLine-iconTitle {
    left: -34px;
  }
  .progressLine-bankData .progressLine-iconTitle {
    left: -18px;
  }
  .progressLine-docs .progressLine-iconTitle {
    left: -20px;
  }

  .progressIcon-prev {
    background: #00bcd4;
    color: #fff;
  }
  .progressIcon-next {
    border-color: #ccc;
    color: #ccc;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }
  .react-datepicker__input-container {
    input {
      width: 100%;
      padding: 7px 12px !important;
      // border: 1px solid #ddd;
      border-radius: 3px;
      height: 28px;
    }
  }

  .addressHandWriting {
    cursor: pointer;
  }
  input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #2e3c47;
    opacity: 0.8;
    font-size: 12px;
  }

  input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #2e3c47;
    opacity: 0.8;
    font-size: 12px;
  }

  input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #2e3c47;
    opacity: 0.8;
    font-size: 12px;
  }
  .profileDocs {
    .profileDocs-desc {
      opacity: 0.8;
      width: 65%;
      padding-top: 18px !important;

      a {
        color: #2e3c47;
        opacity: 0.8;
      }
    }
  }
  .table .adaptiveCell {
    padding-right: 0 !important;
    .uniform-uploader {
      margin-right: 10px !important;
    }
  }
  // .uniform-uploader input[type=file] {width: auto !important;}
  .table .btn.action {
    border-radius: 4px;
    width: 145px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    .uploadIcon {
      margin-right: 12px;
      margin-bottom: 3px;
    }
  }
  .btn {
    font-size: 14px;
    font-weight: 600;
    width: 100px;
    height: 30px;
    padding: 0 !important;
    line-height: 28px;
    letter-spacing: 0.19px;
  }
  .addBank {
    width: auto;
    padding: 0 10px !important;
  }
  .btnSubmits {
    position: absolute;
    bottom: -46px;
    left: 0;

    .btn {
      font-size: 14px;
      font-weight: 600;
      width: 100px;
      height: 30px;
      padding: 0 !important;
      line-height: 28px;
      letter-spacing: 0.19px;
    }
    .anketaDocs-submitBtnSend {
      width: 120px;
    }
  }
  .profileDocs-ctrlBtns {
    margin-top: 90px !important;
    .btn-success {
      width: 145px !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
  .checkboxContainer {
    font-size: 9px;
    padding-top: 0;
    letter-spacing: 0.05px;
    padding-left: 17px;
    margin-bottom: 8px !important;

    .checkmark {
      top: 0px;
      width: 12px;
      height: 12px;
    }
    .checkmark:after {
      left: 3px;
      top: 0px;
      width: 5px;
      height: 8px;
    }
  }
  .profileDocs-uploaderTitle {
    font-size: 10px;
    letter-spacing: 0.12px;
    color: rgba(46, 60, 71, 0.6);
    margin-bottom: 4px;
    line-height: 12px;
  }
  .uploaderTitleCheck {
    font-size: 10px;
    margin-left: 6px;
    margin-top: 2px;
  }
  .profileDocs-uploaderBtn {
    display: inline-block;
    margin-right: 0;
    margin-left: 0;
  }
  .profileDocs-uploaderBtn .action {
    border-radius: 2px;
  }
  .profileDocs-uploaderCheck {
    // font-size: 30px;
  }
  .docRemoveFile {
    font-size: 10px;
    margin-top: 2px;
  }
  .radioBtn-container {
    margin-top: 18px;
    input {
      margin-top: 2px;
      margin-right: 10px;
    }
  }

  .justifiedBtnGroup {
    display: flex !important;
  }
  .justifiedBtnGroup label {
    flex: 1 !important;
  }
  .btn-group-toggle > .btn input[type='checkbox'],
  .btn-group-toggle > .btn input[type='radio'],
  .btn-group-toggle > .btn-group > .btn input[type='checkbox'],
  .btn-group-toggle > .btn-group > .btn input[type='radio'] {
    clip: none !important;
  }

  .anketaChange-subtitleContainer {
    margin-bottom: 20px;
  }
  .anketaChange-subtitle {
    font-size: 16px;
    opacity: 0.8;
    letter-spacing: 0.08px;
    font-weight: 600;
  }
  .addressHandWriting {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.06px;
    color: #5672db;
  }
  .SingleDatePicker_picker {
    position: fixed;
    top: 200px !important;
    left: 50% !important;
    margin-left: 60px;
    z-index: 11;
  }
  .SingleDatePickerInput .DateInput .DateInput_input {
    padding: 8px 5px !important;
  }
}
