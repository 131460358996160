.tech {
    width: 1100px;
}
.tech .card-content {width: 100%;}
.techContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.tech-table {width: 780px; margin-right: 12px;}
.tech-options {width: 300px;}

.tech .nav-tabs {
    margin-bottom: 0;
    border-bottom: none;
}
.tech-searchContainer {
    padding: 20px 20px 10px;
}
.tech-search {
    position: relative;
    width: 220px;
}
.tech-search .inputUnderLine, 
.tech-search .inputUnderLine:focus {width: 100%; padding-right: 25px !important; font-size: 14px; line-height: 25px;}
.searchIcon {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -10px;
}

.tech-table .scroller:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.05) 0%, transparent 100%);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.05) 0%, transparent 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.05) 0%, transparent 100%);
}
.tech-table .table tbody tr:nth-child(2n) {
    background: #f5f6fa;
}
.tech-table .nav-tabs .nav-item {
    width: 110px;
    font-size: 13px;
    letter-spacing: 0.15px;
    margin-bottom: -5px;
}

.tech-table .table tr td {
    text-align: right;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 12px;
    box-sizing: border-box;
}
.tech-table .table .transactionTableInst {width: 17%;}
.tech-table .table .transactionTableName {width: 18%;}
.tech-table .table .transactionTableZayavki {width: 18%;}
.tech-table .table .transactionTableLot {width: 15%;}
.tech-table .table .transactionTableShort {width: 10%;}
.tech-table .table .transactionTableDostup {width: 22%;}
.tech-tableTitles td {font-weight: 500;}

// .tech-table .clipper, .tech-table .scroller {height: calc(100vh - 50px);}
.tech-table .clipper, .tech-table .scroller {
    height: calc(100vh - 300px);
    min-height: 258px;
    max-height: none;
}

.tech-options .card {margin-top: 43px; margin-bottom: 0;}
.tech-options ul {margin: 0; list-style: none;}
.tech-option {
    padding: 8px 16px;
    border-bottom: 1px solid #E8EBED;
}
.tech-option:last-child {border-bottom: none;}

.tech-optionTitle {font-size: 11px; line-height: 16px;}
.tech-optionValue {font-size: 11px; line-height: 24px; font-weight: bold;}


.tech-options .customSelect {width: 150px; height: 28px;}
.tech-options .customSelect .customSelectedItem {
    font-size: 11px;
    line-height: 28px;
    padding-left: 6px;
    border-radius: 4px;
}
.tech-options .customSelectList {margin-top: 6px !important; border: none !important;}
.tech-options .customSelect {margin-top: 8px;}
.tech-options .customSelect ul {padding: 4px 0; border-radius: 4px; background: #fff; box-shadow: -1px 2px 8px rgba(0, 0, 0, 0.08);}
.tech-options .customSelect ul li {padding-left: 6px; font-size: 11px; background: #fff;}
.tech-options .customSelect ul li:hover {background: #f2f3fa;}
.tech-options .customSelect .toggle {top: 3px;}