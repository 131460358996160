@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800,900");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,500,600,700&display=swap");
@font-face {
  font-family: "Helvetica Neue Cyr Roman";
  src: url("/../fonts/HelveticaNeueCyr-Roman.woff") format("woff"),
    url("/../fonts/HelveticaNeueCyr-Roman.eot"),
    url("/../fonts/HelveticaNeueCyr-Roman.eot?#iefix")
      format("embedded-opentype"),
    url("/../fonts/HelveticaNeueCyr-Roman.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: "Montserrat", sans-serif !important;
}
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  height: 100vh;
}

// CUSTOM BOOTSTRAP STYLE
body {
  background-color: #f5f6fa !important;
  font-weight: 500 !important;
}
body {
  .btn {
    padding-left: 35px !important;
    padding-right: 35px !important;
    font-weight: 600;
    font-size: 15px;
    font-family: "Montserrat", sans-serif !important;
  }
  .btn-light,
  .btn-light:hover {
    background: white !important;
    border-color: #9013fe !important;
  }
  body .btn-success,
  body .btn-success:hover {
    background-color: #5aad5f !important;
    border-color: #5aad5f !important;
  }
  .btn-white,
  .btn-white:hover {
    background-color: white !important;
    border-color: #5672db !important;
    color: #5672db;
    box-shadow: none !important;
  }
  .text-success {
    color: #5aad5f !important;
  }
  .text-primary {
    color: #5672db !important;
  }
  .text-danger {
    color: #d06161 !important;
  }
  .card {
    border: none !important;
    border-radius: 6px !important;
    box-shadow: -3px 3px 21px 0 rgba(0, 0, 0, 0.1) !important;
  }
  label {
    margin-bottom: 0 !important;
    padding-top: 6px;
    opacity: 0.8;
    font-size: 12px;
    padding-right: 2px !important;
  }
  .form-group select {
    background: url(https://cdn2.iconfinder.com/data/icons/font-awesome/1792/sort-16.png)
      no-repeat right #f2f3fa;
    -webkit-appearance: none;
    background-position-x: calc(100% - 10px);
  }
  .form-group .react-datepicker select {
    background: white;
    -webkit-appearance: menulist;
  }
  .form-control:disabled,
  .form-control[readonly] {
    background-color: #f2f3fa !important;
  }
  .form-control {
    border: none !important;
  }
  .react-datepicker__input-container input {
    border: none;
    outline: none;
    background-color: #f7f7fc;
    font-size: 12px;
    color: rgba(46, 60, 71, 0.8);
    letter-spacing: 0.06px;
  }
  .react-datepicker__header {
    border-left: 1px solid #aeaeae;
  }

  // .form-control:not(.border-1):not(.border-2):not(.border-3):focus {border: none !important;}
  // .react-datepicker__input-container input:focus {border: 1px solid #9013fe !important; border-color: #9013fe !important;}
  .react-datepicker-popper {
    z-index: 5 !important;
  }

  .table td,
  .table th {
    border-top: none !important;
    padding-left: 1rem;
  }

  .btn-group-toggle label,
  .btn-group-toggle label:hover {
    background: white;
    border: 1px solid #9013fe;
    color: #9013fe;
    box-shadow: none !important;
    font-weight: 600;
  }
  .btn-group-toggle label.active {
    background: #9013fe;
    border: 1px solid #9013fe;
    color: white;
  }
  .btn-group-toggle label.active:hover {
    background: #9013fe;
  }
  .btn.disabled,
  .btn:disabled {
    opacity: 0.4 !important;
  }
  .sidebar:not(.sidebar-component) .sidebar-content {
    top: 0;
  }

  .sidebar .nav-link.active {
    color: #d06161 !important;
    background-color: transparent !important;

    .filled {
      fill: #d06161;
    }
    .stroked {
      stroke: #d06161;
    }
  }
  .sidebar-content .nav-sidebar .nav-link {
    display: flex;
    align-items: center;
    padding: 5px 25px !important;
  }
  .nav-sidebar .nav-link span {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.2px;
    
    &.sidebar-title {
      width: 160px;
    }
  } 

  .sidebar .sidebar-content {
    background-image: linear-gradient(to top, #4574a0, #1b3450);
  }
  .sidebar-content .card {
    box-shadow: none !important;
  }
  .content {
    padding-top: 30px !important;
    // overflow-y: auto;
    // height: 100vh;
  }
  .content-wrapper {
    overflow: visible !important;
  }
}
////
.broadcasts-page-content > .clipper > .scroller {
  overflow: hidden;
}
.broadcasts-page-content > .clipper > .track {
  display: none;
}
.page-content > .clipper,
.page-content > .clipper > .scroller {
  width: 100%;
  max-height: none;
  height: 100vh;
  margin: 0 auto !important;
}
.page-content-center {
  margin: 0 auto;
}
.card-content {
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  // overflow: hidden;
}
@media screen and (max-width: 992px) {
  .card-content {
    width: auto;
  }
}
.title {
  font-weight: 800;
  font-size: 24px;
  color: #2e3c47;
  margin-bottom: 20px;
  margin-top: 0;
  opacity: 0.8;
}
.subtitle {
  font-weight: 500;
  font-size: 20px;
}
.hidden {
  display: none;
}
.floating-label {
  position: absolute;
  top: -17px;
  left: 0;
  font-size: 10px !important;
  opacity: 0.6;
  color: #2e3c47;
}
.textSum-minus {
  color: #d06161;
}
.textSum-plus {
  color: #379f2e;
}
.bgSum-minus {
  background-color: #f0c1c1 !important;
  color: #353a3e;
}
.bgSum-plus {
  background-color: #cef0d8 !important;
  color: #353a3e;
}
.bgSumTotal-minus {
  background-color: #e6b5b5 !important;
  color: #353a3e;
}
.bgSumTotal-plus {
  background-color: #c5e6cf !important;
  color: #353a3e;
}

.nav-link {
  cursor: pointer;
}
.sub-active {
  background-color: rgba(0, 0, 0, 0.15);
}
.nav-item-submenu.close-nav > ul {
  display: none;
}
.nav-item-submenu.open-nav > ul {
  display: block;
}

.flexableBlock {
  min-height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1;
  flex: 1;
}
.flexableBlock.asUser {
  padding-top: 109px;
}
.asUser .sidebar .sidebar-content {
  top: 109px;
}

.navbar-top {
  padding-top: 0 !important;
  overflow: hidden;
}
.sidebar-content::-webkit-scrollbar {
  display: none;
}

thead tr td {
  font-weight: bold;
}
// .table-container {max-height: 45vh; overflow-y: scroll;}

.tableLayoutFixes {
  width: 100%;
  table-layout: fixed;
}
.table-container tbody {
  position: relative;
  display: block;
  max-height: 45vh;
  overflow: auto;
  overflow-x: hidden;
}
.table-container tbody tr {
  width: calc(100% + 6px) !important;
}
.table-container tbody::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

/* Track */
.table-container tbody::-webkit-scrollbar-track {
  // background: rgba(0,0,0,0);
  // opacity: 0;
  // background: transparent url('/../images/ui/transparent.png') repeat-y !important;
}
.table-container tbody::-webkit-scrollbar-track-piece:start {
  // background: transparent url('/../images/ui/transparent.png') repeat-y !important;
}

.table-container tbody::-webkit-scrollbar-track-piece:end {
  // background: transparent url('/../images/ui/transparent.png') repeat-y !important;
}

/* Handle */
.table-container tbody::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
  opacity: 0.1;
  border-radius: 15px;
}

/* Handle on hover */
.table-container tbody::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
  opacity: 0.3;
}
.table-container thead,
.table-container tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}
.pagination > li {
  display: inline;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #337ab7;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}

.tableScrollContainer {
  overflow: auto;
}
.printClass {
  margin: 15px;
}
.printClass .tableScrollContainer {
  overflow: visible;
}
.printClass .tableDealsContainer td {
  padding: 0;
}

.hintNote {
  font-size: 12px;
  margin-bottom: 50px !important;
  color: #2e3c47;
  opacity: 0.5;
}

.hintBlock {
  margin-top: 5px;
  padding: 10px 15px;
  background: #abefe5;
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;

  &.files {
    margin-top: 0;
    margin-bottom: 15px;
    padding: 20px 25px;

    h4 {
      margin-bottom: 5px;
    }

    ul {
      list-style: none;
      padding-left: 10px;
      margin-bottom: 5px;

      li {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          left: -8px;
          top: 6px;
          transform: translateY(-50%);
          width: 2px;
          height: 2px;
          border-radius: 50%;
          background-color: #000;
        }
      }
    }

    a { 
      padding-left: 10px;
    }
  }

  &__text {
    width: 70%;
  }
}

.adaptiveCell {
  white-space: nowrap;
}

.docRemoveFile {
  display: inline-block;
  cursor: pointer;
  margin-left: 4px;
  margin-right: 4px;
}
.uploaderCheck {
  font-size: 30px;
}

.tableFilters-container {
  margin: 18px !important;
}

// Progress line steps
.progressLineContainer {
  position: relative;
  text-align: center;
  margin: 20px auto 95px;
  width: 80%;
}
.progressLineContainer svg {
  display: inline-block;
}
.progressLine-iconContainer {
  position: absolute;
  top: -8px;
  left: 0;
  margin-left: -50px;
  text-align: center;
}
.progressLine-iconContainer i,
.progressLine-iconContainer span {
  line-height: 34px;
  font-size: 20px;
}

.progressLine-iconContainer-icon {
  color: #00bcd4;
  border-radius: 50%;
  border: 2px solid #00bcd4;
  width: 38px !important;
  display: inline-block;
  background: white;
}
.progressLine-iconContainer p {
  margin-bottom: 0;
  width: 100px;
}
.progressLine-adressess {
  left: 25%;
}
.progressLine-addData {
  left: 50%;
}
.progressLine-bankData {
  left: 75%;
}
.progressLine-docs {
  left: 100%;
}

.progressIcon-prev .progressLine-iconContainer-icon {
  background: #00bcd4;
  color: #fff;
}
.progressIcon-next .progressLine-iconContainer-icon {
  border-color: #ccc;
  color: #ccc;
}

.docRemoveFile {
  display: inline-block;
  cursor: pointer;
  margin-left: 4px;
  margin-right: 4px;
}
.uploaderCheck {
  font-size: 30px;
}

.inputWOLabel {
  margin-top: 28px;
}

.docNeedScan-links a {
  min-width: 125px;
}
.profileDocs-uploaderBtn {
  display: inline-block;
}
.docNeedScan-upload a {
  cursor: pointer;
  display: inline-block;
}

.profileDocs-uploaderCheck {
  font-size: 18px;
  margin-right: 8px;
  margin-top: -2px;
}
.cancelTypeDoc-icon {
  font-size: 65px;
}
.repeatSendSms {
  cursor: pointer;
}

.uniform-uploader .action {
  border-radius: 0.1875rem;
}

.accountFilter {
  width: auto;
  display: inline-block;
  margin-right: 20px;
  width: 100px;
  padding-left: 5px;
  padding-right: 0;
  vertical-align: top;
  height: 36px;
}

/* DATA RANGE PICKER */
.DateInput {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  padding: 3px;
  width: 90px;
  margin-left: 0 !important;
  margin-right: 12px !important;
}

.DateInput__display-text,
.DateInput__display-text--focused {
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.DateInput__display-text--focused {
  background: transparent;
  border-color: transparent;
  border-radius: 0;
  color: #484848;
}
.profileDocs-uploaderBtn .action {
  padding-left: 20px;
  padding-right: 20px;
}
.profileDocs-uploaderBtn .action {
  padding-left: 20px;
  padding-right: 20px;
}
.bg-purple {
  background-color: #9013fe;
}

// Pure css select 2 TOGGLE
.select2 {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: block;
  width: calc(100% - 10px);
  height: auto;
  border-bottom: 0px;
  border-radius: 3px;
  font-size: 12px;

  background-position-x: calc(100% - 10px);
  background: url(https://cdn2.iconfinder.com/data/icons/font-awesome/1792/sort-16.png)
    no-repeat right #f2f3fa;
  label {
    width: 100%;
    margin-bottom: 0;
  }
  i.toggle {
    position: absolute;
    z-index: 2;
    right: 12px;
    top: 10px;
    color: #2e3c47;
  }
  .select-title,
  .placeholder {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    padding: 10px;
    padding-bottom: 6px;
    background: #f2f3fa;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    cursor: pointer;
    font-size: 12px;
  }
  & > label > input {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 2;
    width: 100%;
    height: 36px;
    display: block;
    opacity: 0;
    cursor: pointer;
    &:checked {
      z-index: 2;
      ~ i.toggle.icon-plus {
        display: none;
      }
      ~ i.toggle.icon-minus {
        display: block;
      }
    }
    &:not(:checked) {
      ~ i.toggle.icon-minus {
        display: none;
      }
      ~ i.toggle.icon-plus {
        display: block;
      }
      ~ label.option input:not(:checked) ~ .select-title {
        display: none !important;
      }
    }
    &:disabled {
      cursor: no-drop;
    }
  }
  & label > span.placeholder {
    position: relative;
    z-index: 0;
    display: inline-block;
    width: 100%;
    color: #2e3c47;
    border-top: 0px;
  }
  label.option {
    display: block;
    overflow: hidden;
    z-index: 1;
    width: 100%;
    transition: all 1s ease-out;
    span.select-title {
      position: relative;
      z-index: 1;
      transition: background 0.3s ease-out;
      &:hover {
        color: #2e3c47;
        background: #dddeed;
      }
    }
    input {
      display: none;
      &:checked ~ span.select-title {
        position: absolute;
        display: block;
        z-index: 1;
        top: 0px;
        font-size: 12px;
        background: #f2f3fa;
        border-top: 0px;
        box-shadow: none;
        color: inherit;
        width: 100%;
        height: 36px;
      }
      &:disabled {
        & ~ span.select-title {
          background: #f2f3fa !important;
          color: #2e3c47;
          &:hover {
            color: #2e3c47;
            background: none;
            cursor: no-drop;
          }
        }
      }
    }
  }
}

/* DADATA COMPONENT */
.dadataContainer {
  position: relative;
  display: inline-block;
  width: 100%;
}
.dadataContainer .dadataList {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  list-style: none;
  background: white;
  z-index: 10;
  border: 1px solid rgba(74, 74, 74, 0.2);
}
.dadataContainer .dadataList li {
  padding: 7px 14px;
  border-top: 1px solid rgba(74, 74, 74, 0.1);
  cursor: pointer;
  background: #f2f3fa;
}
.dadataContainer .dadataList li:first-child {
  border-top: none;
}
.dadataContainer .dadataList li:hover {
  background: #dddeed;
}
.inaccessibleIcon, .forbiddenIcon {
  position: absolute;
  top: 10px;
  left: -10px;
  height: 15px;
  transform: translateY(-50%);
  filter: invert(48%) sepia(8%) saturate(3651%) hue-rotate(314deg) brightness(99%) contrast(83%);
}
.inaccessibleIcon {
  filter: invert(29%) sepia(98%) saturate(7482%) hue-rotate(359deg)
    brightness(109%) contrast(123%);
}
.filterToRed {
  filter: invert(48%) sepia(8%) saturate(3651%) hue-rotate(314deg) brightness(99%) contrast(83%);
}
.filterToGreen {
  filter: invert(56%) sepia(55%) saturate(392%) hue-rotate(74deg) brightness(96%) contrast(88%) !important;
}
.filterToSecondary {
  filter: invert(54%) sepia(0%) saturate(20%) hue-rotate(204deg) brightness(85%) contrast(90%);
}

.formWarnAlert {
  margin: -8px auto 20px;
  width: 700px;
  position: relative;
  border-radius: 4px;
  padding: 24px 36px;
  background-color: #fff;

  h3 {
    color: #2E3C47;
    font-size: 16px;
    line-height: 19.5px;
    font-weight: 600;
    margin: 0;
  }

  p {
    color: #58636C;
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
    margin: 8px 0 0 0;
    letter-spacing: 0.061875px;
  }
}

.formWarnAlert::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 6px;
  background: #5672DB;
  border-radius: 4px 0px 0px 4px;
}
.formWarnAlert.warn {
  background: #FDFFEE;

  h3 {
    margin-left: 9.5px;
  }
}
.formWarnAlert.warn::before {
  background: #DED15F;
}

@media (max-width: 500px) {
  body {
    .content {
      padding-top: 23.5px !important;
    }
  }
}

/* Positions and Orders */
.borderless {
  border: none;
}

.clear-backgroud {
  background: transparent;
}

.closePosition, .cancelOrder {
  cursor: pointer;
}

button:disabled {
  opacity: 0.5;
}
