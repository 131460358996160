.transactions {
  .finresFilters {
    font-size: 14px;
    font-weight: 500;
    // margin: 17px 24px;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      margin-bottom: 0.3rem;
    }
  }
  .finresFilters-title-customPeriod {
    margin-top: 28px;
  }
  .accountFilter {
    width: auto;
  }
  .finresTotals {
    border-top: 1px solid rgba(35, 68, 106, 0.21);
  }
  .filter-subtitle {
    font-size: 13px;
  }
  .totalSubTitle {
    color: #2e3c47;
    opacity: 0.5;
    font-size: 13px;
  }
  .totalSubTitle-num {
    font-size: 13px;
  }

  .tableFilters-dates {
    display: inline-block;
    vertical-align: top;
    width: 246px;
  }
  .tableFilters-types {
    display: inline-block;
    vertical-align: top;
    width: 160px;
    margin-left: 10px;
    margin-right: 56px;
  }
  .tableFilters-totals {
    display: inline-block;
    vertical-align: top;
    width: 190px;
  }
  .tableFilters-datesRangeWeek,
  .tableFilters-datesRangeMonth,
  .tableFilters-datesRangeQuarter {
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
  }

  .scroller {
    box-shadow: inset 0 8px 10px -3px rgba(35, 68, 106, 0.05);
    border-radius: 0 0 6px 6px;
  }
  .table {
    border-top: 1px solid #f2f3fa;
  }

  .finres-tableTitles td {
    color: #2e3c47;
    font-size: 12px;
    font-weight: 600;
    padding: 8px 5px;
  }

  .table tbody tr:nth-child(2n) {
    background: #f5f6fa;
  }
  .table tbody + tbody {
    border-top: none;
  }
  .table tr td {
    text-align: right;
    padding: 8px 5px;
    font-size: 12px;
  }

  .table {
    .transactionTableData {
      box-sizing: border-box;
      width: 12%;
    }
    .transactionTableDesc {
      box-sizing: border-box;
      width: 17%;
      padding-left: 30px;
    }
    .transactionTablePlace {
      box-sizing: border-box;
      width: 17%;
    }
    .transactionTableFinres {
      box-sizing: border-box;
      width: 9%;
    }
    .transactionTablePayout {
      box-sizing: border-box;
      width: 16%;
    }
    .transactionTableSum {
      box-sizing: border-box;
      width: 17%;
      padding-right: 40px;
    }
  }

  .finresAccount {
    margin-bottom: 50px;
  }
  .finresBoard {
    margin-bottom: 20px;
  }
  .dateRangeWeek .activePeriodWeek,
  .dateRangeMonth .activePeriodMonth,
  .dateRangeQuarter .activePeriodQuarter {
    background: #5672db;
    color: white;
  }
  .dateRangeCustom .DateInput {
    border-bottom: 1px solid #5672db;
  }

  .DateRangePicker {
    // margin-right: 20px;
  }
  .DateRangePickerInput {
    border: none;
  }
  .DateRangePickerInput__arrow {
    display: none;
  }
  .DateInput {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    margin-right: 12px !important;
    text-align: center;
    width: 70px;

    input {
      text-align: center;
    }
  }
  .DateInput__display-text--has-input {
    color: darkgray;
  }
  .DateInput__display-text--focused {
    background: transparent;
    border-color: transparent;
    border-radius: 0;
    color: #484848;
  }
  .DateRangePickerInput {
    width: auto !important;
  }

  .emptyDashboard-plug {
    position: relative;
    height: calc(100vh - 315px);
    .emptyData {
      top: calc(50% + 15px);
    }
  }
  .emptyDashboard-plug:before {
    content: '';
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    height: 10px;
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }

  .clipper {
    height: calc(100vh - 350px);
    max-height: none !important;
  }
  .scroller {
    position: relative;
    box-shadow: none;
    border-radius: 0 0 6px 6px;
    height: calc(100vh - 350px);
    max-height: none !important;
  }
  .scroller:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }
}
