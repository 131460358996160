.sidebar-userpic {
  width: 64px;
}
.sidebar-userAccount {
  width: 100%;
}
.sidebar-userName {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 0.1px;
}
.userAcc-arrowIcon {
  margin-right: 8px;
  margin-bottom: 2px;
  font-size: 12px;
}
.sidebar-balanceAccMain {
  font-size: 17px;
  font-weight: 500;
}
.sidebar-balanceAccSub {
  opacity: 0.3;
  font-size: 12px;
}
.sidebar .balanceCurrency {
  font-size: 16px;
  padding: 0;
  transition: none;
}
.balanceMoreCurrency {
  font-size: 11px;
  padding: 0;
  transition: none;
}

.sidebar-accounts > span:first-child {
  color: white;
  font-size: 15px;
  font-weight: 500;
}
.sidebar-accounts > span {
  color: rgba(255, 255, 255, 0.3);
  font-size: 12px;
}
.sidebar-content hr {
  border-top-color: rgba(255, 255, 255, 0.1);
}
.sidebar-xs .accountSelect,
.sidebar-xs .sidebar-user {
  display: none;
}

.sidebar .nav-sidebar .nav-link > .sidebar-title {
  margin-left: 42px;
}

.sidebar {
  .sidebar-miniCtrl {
    position: absolute;
    z-index: 10;
    top: 7px;
    left: 4px;

    .sidebar-control {
      padding: 0.875rem 25px !important;
    }
  }
  .customSelect {
    height: 100px;
    color: hsl(206, 21%, 23%);
    text-align: right;

    &.crypto {
      height: 117px;
    }
  }
  .customSelect .customSelectedItem {
    background: transparent;
    color: white;
    border-radius: 0;
    text-align: right;
    padding: 20px 25px 20px 40px;
    line-height: 22px;
    display: inline-block;
  }
  .customSelect ul {
    background: #203b59;
    border-top: none;
    top: 0;
    box-shadow: -2px 3px 7px 0 rgba(21, 27, 51, 0.62);
  }
  .customSelect ul li {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    height: 100px;
    background: transparent;
    color: white;
    border-radius: 0;
    text-align: right;
    padding: 20px 25px;
    line-height: 22px;
  }
  .customSelect.crypto ul li {
    height: 117px;

    .sidebar-accTrading {
      height: 25px;
    }
    .sidebar-accDemo {
      font-size: 14px;
      top: 25%;
    }
  }
  .customSelect ul li:hover {
    background: rgba(255, 255, 255, 0.05);
  }
  .sidebar-accounts-bonus-icon {
    width: 25px;
    height: 25px;
    margin-left: 9px;
  }
  .sidebar-accName.transparent,
  .sidebar-accSecurity.transparent {
    visibility: hidden;
  }
  .sidebar-accName,
  .sidebar-accSecurity {
    opacity: 0.3;
    font-size: 12px;
  }
  .sidebar-accBnbBalance {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    font-size: 12px;

    & > img {
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }
  }
  .sidebar-accTrading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 17px;
    font-weight: 500;
    white-space: nowrap;

    & > img {
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }
  }
  .sidebar-accDemo {
    position: absolute;
    top: 50%;
    left: 25px;
    margin-top: -10px;
    font-size: 17px;
    font-weight: 500;
  }
  .customSelect .toggle {
    position: absolute;
    z-index: 2;
    right: 7px;
    top: 50%;
    font-size: 14px;
    color: white;
    margin-top: -4px;
  }
  .customSelect.crypto .toggle {
    top: 45%;
  }
  .sidebar-docsLink {
    position: relative;
  }
  .sidebar-item-icon {
    position: relative;
  }
  span.sidebar-docsCount {
    position: absolute;
    top: 0;
    right: 0;
    width: 16px;
    transform: translate(40%, -22%);
    line-height: 16px;
    font-size: 9px !important;
    color: white;
    background-color: #d06161;
    text-align: center;
    border-radius: 50%;
  }
}
.sidebar-xs .customSelect {
  display: none;
}
.sidebar-xs .card-sidebar-mobile {
  padding-top: 50px;
}

.sidebar-xs .sidebar-main {
  width: 4.5rem;
  transition: none;
  position: static;
}
.sidebar-main,
.sidebar-main .sidebar-content,
.sidebar-secondary,
.sidebar-secondary .sidebar-content {
  left: 0 !important;
}
@media (min-width: 768px) {
  .sidebar-xs .sidebar-main {
    width: 74px !important;
  }
}

.sidebar-close-toggle {
  width: 11.8px;
  height: 11.09px;
}


.sidebar-socials {
  padding: 0px 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  list-style: none;
}
.sidebar .customSelect .header-logo {
  display: none;
}
@media (min-width: 768px) {
  .sidebar-xs .sidebar-socials {
    flex-direction: column;
  }
  .sidebar-xs .sidebar-socials li {
    margin: 10px 0;
  }
}

@media (max-width: 500px) {
  .sidebar-content.openedSidebar {
    overflow-y: visible !important;
  }
  .sidebar-xs .page-content {
    margin-top: 50px;
  }
  .page-content .sidebar-main {
    width: 100vw;
  }
  .sidebar .customSelectList {
    max-height: 100vh;
    overflow-y: auto;
  }
  .sidebar .customSelect {
    display: flex;
    align-items: center;
    height: 56px;
  }
  .sidebar-xs .sidebar-dark {
    position: fixed;
    height: 0;
  }
  .sidebar .sidebar-miniCtrl {
    top: 28px;
    transform: translateY(-49%);
  }
  .sidebar .customSelect .customSelectedItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 0;
  }
  .sidebar .customSelect .toggle {
    display: none;
  }
  .sidebar-xs .sidebar:not(.bg-transparent) .card {
    display: none;
  }
  .sidebar-xs .sidebar:not(.bg-transparent) .card {
    display: none;
  }
  .sidebar .sidebar-accName {
    font-size: 10px;
    line-height: 12px;
  }
  .sidebar .sidebar-accTrading {
    font-size: 14px;
    line-height: 17px;
  }
  .sidebar .sidebar-miniCtrl .sidebar-control {
    padding: 0.875rem 1rem !important;
  }
  .sidebar .customSelect .header-logo {
    display: block;
    position: relative;
    left: 67px;
  }
}