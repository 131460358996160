.finres {
  .dailyFinres .dailyFinres-boardTable > thead > tr > td, .dailyFinres .dailyFinres-boardTable > tbody > tr > td {
    width: calc(100% / 6);
    padding: 0.75rem 1.25rem;
  }
  .card-content {
    width: 1000px;
  }
  .infoModal {
    width: 275px;
    position: absolute;
    z-index: 1;
    top: 0;
    right: -10px;
    transform: translate(100%, -30px);
    padding: 20px;
    border-radius: 5px;
    border: 1px solid #f2f3fa;
    background-color: #fff;
    box-shadow: -3px 3px 21px 0 rgba(0, 0, 0, 0.1);

    p {
      font-size: 12px;
      font-weight: 400;
    }

    &.tableTitle {
      z-index: 5;
      right: 0px;
      transform: translate(calc(50% - 65px), -100%);
      text-align: left;
    }
  }
  .finresPlatform {
    display: flex;
    align-items: center;
    margin-left: 15px;
    padding: 0 10px;
    border-radius: 5px;
    background: #5672db;
    font-size: 15px;
    font-weight: 400;
    color: #fff;
  }
  .finresFilters {
    font-size: 14px;
    font-weight: 500;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      margin-bottom: 0.3rem;
    }
  }
  .accountFilter {
    width: auto;
  }
  .filter-subtitle {
    font-size: 13px;
  }
  .totalSubTitle {
    color: #2e3c47;
    opacity: 0.5;
    font-size: 13px;
  }
  .totalSubTitle-num {
    font-size: 13px;
  }
  .tableFilters-datesRangeWeek,
  .tableFilters-datesRangeMonth,
  .tableFilters-datesRangeQuarter,
  .tableFilters-datesRange30days,
  .tableFilters-datesRange90days,
  .tableFilters-filter10pnls,
  .tableFilters-filter40pnls,
  .tableFilters-filter100pnls,
  .tableFilters-filterAllpnls {
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
  }
  .finresFilters-datePeriod div span {
    font-size: 12px;
    padding: 2px 8px;
    border-radius: 8px;
    cursor: pointer;
  }
  .finresFilters-title-customPeriod {
    margin-top: 28px;
  }
  .finresTotalsContainer {
    position: relative;
    height: 100%;
    
    .totalData {
      p {
        font-size: 12px;
      }
    }
  }
  .finresTotals {
    position: absolute;
    width: calc(100% + 1.3rem);
    bottom: 15px;
  }

  .table thead {
    border-top: 1px solid #f2f3fa;
  }
  .finresFirstBoard .finres-tableBoardName {
    box-shadow: inset 0 8px 10px -3px rgba(35, 68, 106, 0.05);
  }

  .finres-tableTitles td {
    color: #2e3c47;
    font-size: 12px;
    font-weight: 500;
    padding-top: 9px;
    padding-bottom: 9px;
  }

  .tableFilters-container {
    margin-bottom: 0 !important;
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .clipper {
    border-radius: 0 0 6px 6px;
  }
  // table tbody tr:nth-child(odd) {background: #f5f6fa;}
  .tableCellOdd {
    background: #f5f6fa;
  }

  .table tbody + tbody {
    border-top: none;
  }
  .table tr td {
    text-align: right;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 12px;
  }
  .table {
    .transactionTableData {
      box-sizing: border-box;
      width: 15%;
    }
    .transactionTableFinres {
      box-sizing: border-box;
      width: 21%;
    }
    .transactionTableCommis {
      box-sizing: border-box;
      width: 21%;
    }
    .transactionTableMarj {
      box-sizing: border-box;
      width: 21%;
    }
    .transactionTableProsad {
      box-sizing: border-box;
      width: 22%;
      padding-right: 46px;
    }
  }
  .table tr .finres-tableBoardName {
    // background: white;
    font-size: 16px;
    font-weight: 500;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .finresBoardTotal {
    border-bottom: 1px solid #f2f3fa;
    // background-color: white !important;
  }

  .finresAccount {
    margin-bottom: 50px;
  }
  .finresBoard {
    margin-bottom: 20px;
  }

  .dateRangeWeek .activePeriodWeek,
  .dateRangeMonth .activePeriodMonth,
  .dateRangeQuarter .activePeriodQuarter,
  .dateRange30days .activePeriod30days,
  .dateRange90days .activePeriod90days,
  .filter10pnls .activeFilter10pnls,
  .filter40pnls .activeFilter40pnls,
  .filter100pnls .activeFilter100pnls,
  .filterAllpnls .activeFilterAllpnls {
    background: #5672db;
    color: white;
  }

  .dateRangeCustom .DateInput {
    border-bottom: 1px solid #5672db;
  }

  .DateRangePicker {
    // margin-right: 20px;
  }
  .DateRangePickerInput {
    border: none;
  }
  .DateRangePickerInput__arrow {
    display: none;
  }
  .DateInput {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    padding: 0;
    width: 70px;
    margin-right: 12px !important;
    text-align: center;

    input {
      text-align: center;
    }
  }
  .DateInput__display-text--has-input {
    color: darkgray;
  }
  .DateInput__display-text--focused {
    background: transparent;
    border-color: transparent;
    border-radius: 0;
    color: #484848;
  }
  .DateRangePickerInput {
    width: auto !important;
  }

  .emptyDashboard-plug {
    position: relative;
    height: calc(100vh - 290px);
    .emptyData {
      top: calc(50% + 18px);
    }
  }
  .emptyDashboard-plug:before {
    content: '';
    position: absolute;
    top: 36px;
    left: 0;
    width: 100%;
    height: 10px;
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }

  .clipper {
    height: calc(100vh - 327px);
    max-height: none !important;
  }
  .scroller {
    position: relative;
    box-shadow: none;
    border-radius: 0 0 6px 6px;
    height: calc(100vh - 327px);
    max-height: none !important;
  }
  .clipper:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }
}
@media screen and (max-width: 992px) {
  .finres .finresTotals {
    position: relative;
    bottom: 0;
  }
}
