.settings {
  .popup_inner.changePassword {
    width: 300px;
    margin-left: -150px;
  }
  .popup_inner.googleAuth {
    width: 400px;
    left: calc(50% + 170px);
  }
  .card-content {
    max-width: 500px;
  }
  .title {
    margin-bottom: 12px;
    font-size: 24px;
    font-weight: 800;
    letter-spacing: 0.28px;
    opacity: 0.8;
  }
  .card-body {
    padding: 28px !important;
    position: relative;
  }

  .clipper,
  .scroller {
    position: static;
    overflow-x: hidden;
    max-height: none !important;
    height: calc(100vh - 180px);
  }
  .track {
    top: 28px;
    bottom: 28px;
  }

  .anketaChange-subtitleContainer {
    margin-bottom: 20px;
  }
  .anketaChange-subtitle {
    font-size: 16px;
    opacity: 0.8;
    letter-spacing: 0.08px;
    font-weight: 600;
  }
  .addressHandWriting {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.06px;
    color: #5672db;
  }
  .settings-liveDataContainer {
    .switchContainer {
      padding-right: 20px;
    }
  }
  .settings-liveDataContainer-title {
    font-size: 12px;
    letter-spacing: 0.06px;
  }
  .settings-changePassTitle {
    text-align: right;

    span {
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.19px;
      color: #5672db;
      cursor: pointer;
      line-height: 28px;
      width: 150px;
      text-align: center;
      border: 1px solid #5672db;
      border-radius: 4px;
    }
  }

  .settings-googleAuthBtn, .settings-showApiKeysBtn {
    text-align: right;

    span, button {
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.19px;
      color: #fff;
      cursor: pointer;
      line-height: 28px;
      padding: 0 37px;
      text-align: center;
      background-color: #5672db;
      border-radius: 4px;
      border: none;
    }

    button:disabled,
    button[disabled] {
      opacity: 0.6;
    }
  }

  .settings-showApiKeysBtn {
    margin-left: 15px;

    span, button {
      padding: 0 30px;

      &.invisible {
        cursor: none;
        opacity: 0;
      }
    }
  }

  .bonusIdItem {
    margin-top: 5px;

    .bonusIdItemIcon {
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }

  .addBonusIdInput {
    display: inline-block;
    cursor: pointer;
    margin-top: 5px;
    border-bottom: 1px dotted rgb(86, 86, 86);
    color: rgb(86, 86, 86);
  }

  .settings-copyIcon {
    position: absolute;
    top: 6px;
    right: 0;
    width: 15px;
    cursor: pointer;
  }

  .btnSubmits {
    padding: 0 24px;

    a,
    button {
      width: 130px;
      height: 30px;
      padding: 0;
      line-height: 28px;
      padding: 0 !important;
    }
    a {
      background-color: transparent !important;
    }
  }
  .inaccessibleIcon, .profileCheckIcon, .forbiddenIcon {
    position: absolute;
    top: 10px;
    left: -20px;
    height: 15px;
    filter: invert(48%) sepia(8%) saturate(3651%) hue-rotate(314deg) brightness(99%) contrast(83%);
    transform: translateY(-50%);
  }
  .profileCheckIcon {
    filter: none;
  }

  &.trading_settings {
    .card-content {
      max-width: 800px;

      .title {
        margin-bottom: 25px;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.15px;
        opacity: 0.8;
      }

      .copyClipboardInput {
        padding-right: 25px !important;
      }
    }
  }
}
