.googleAuthAddDownload {
  & img {
    height: 35px;
  }
  & a {
    color: #000;
  }
}
.googleAuthAddQR {
  & img {
    width: 150px;
  }
}
.googleAuthAddSave {
  & img.pencil {
    width: 150px;
    filter: invert(44%) sepia(46%) saturate(2988%) hue-rotate(211deg)
      brightness(90%) contrast(90%);
  }
}
.googleAuthAddVerify {
  & .errorMessage {
    position: absolute;
    bottom: 20px;
  }
}
.googleAuthAddReady {
  & img {
    width: 100px;
    margin-bottom: 20px;
  }
}
.googleAuthModal {
  position: absolute;
  z-index: 5;
  left: 12%;
  right: 12%;
  top: 50%;
  transform: translateY(-50%);
  max-height: 300px;
  background: white;
  border: 1px solid black;
}
.codeNoMatch, .letterNotArrived {
  cursor: pointer;
  margin: 15px auto 0;
  color: #414bb2;
}