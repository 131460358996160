.analysis {
  .analysis-navLinks {
    margin-bottom: 16px;
    padding-left: 12px;
  }
  .analysis-navLinks .nav-link {
    padding: 0;
    display: inline-block;
    margin-right: 42px;
    font-size: 20px;
    opacity: 0.6;
    letter-spacing: 0.23px;
    font-weight: 800;
    color: #2e3c47;
  }
  .analysis-navLinks .nav-link.active {
    opacity: 1;
    color: #2e3c47 !important;
  }

  .chartContainer {
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    position: relative;
  }
  #chartdiv {
    margin-top: 20px;
  }
  .analysis-equity #chartdiv {
    margin-top: 0;
  }
  .analysis-days .tab-pane {
    position: relative;
  }
  .analysis-equity .tab-pane {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .analysis-equity .chartContainer {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .analysis-symbols .chartContainer {
    padding-right: 0;
  }

  .analysis-symbols .clipper, 
  .analysis-symbols .scroller {
    height: calc(100vh - 250px);
  }

  .chart-logoRemover {
    position: absolute;
    bottom: 12px;
    left: 28px;
    width: 66px;
    height: 21px;
    background: white;
    z-index: 10;
  }
  .analysis-equity .chart-logoRemover {
    bottom: 1px;
  }
  .analysis-days.card-content {
    width: 625px;
  }
  .analysis-symbols.card-content {
    width: 1100px;
  }
  .analysis-equity.card-content {
    width: 1070px;
  }

  .analysis-days .closeDealsBtns {
    position: absolute;
    bottom: 4px;
    right: 0;
  }
  .analysis-symbols .closeDealsBtns,
  .analysis-equity .closeDealsBtns {
    position: absolute;
    bottom: 7px;
    right: 0;
  }

  .analysisSymbols-datesFilter {
    .DateInput {
      width: 70px !important;
      margin-left: 12px !important;
    }
    input {
      border-bottom: 1px solid #5672db;
      text-align: center;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.15px;
    }
    .DateRangePickerInput {
      background-color: transparent !important;
      border: none !important;
    }
    .DateRangePickerInput__arrow {
      display: none !important;
    }
    .DateInput--open-down {
      margin-right: 0 !important;
      margin-left: 12px !important;
      border-bottom: 1px solid #5672db !important;
      width: 85px !important;
      font-weight: 500;
      letter-spacing: 0.15px;
      font-size: 13px !important;
      padding: 0px 4px !important;
      height: 26px !important;
      background: transparent !important;
    }
    .DateInput--open-down input {
      line-height: 22px;
      height: 22px;
    }
    .DateInput__display-text--focused {
      background: transparent !important;
      border-color: transparent !important;
      color: #484848 !important;
    }
  }

  .analysisDay-filterWeek {
    width: 130px;
    position: relative;

    .floating-label {
      text-align: right;
      width: 100%;
    }
    input {
      width: 100%;
      text-align: right;
      height: 26px;
    }
  }
  .analysisEquity-symbolsFilter {
    width: 165px;
    position: absolute;
    bottom: 0;
    left: -185px;
    height: 22px;
    line-height: 22px;

    .customSelect .customSelectedItem {
      padding: 0;
      line-height: 22px;
      border-bottom: 1px solid #5672db;
      background: transparent;
      font-size: 13px;
      font-weight: 500;
      border-radius: 0;
    }
    .customSelectList {
      top: 100%;
      box-shadow: -1px 1px 11px 0 rgba(35, 68, 106, 0.08),
        0 2px 5px 0 rgba(0, 0, 0, 0.08);
      background: white;
    }
    .customSelectList li {
      line-height: 12px;
      padding: 0;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 0.12px;

      label {
        padding: 6px;
        font-size: 10px;
        line-height: 12px;
        padding-left: 26px;
        font-weight: 500;
      }
    }
    .customSelectList li:hover {
      background: white;
    }
    .checkboxContainer {
      opacity: 1;
    }
    .checkboxContainer .checkmark:after {
      left: 3px;
      top: 1px;
      width: 4px;
      height: 7px;
    }
    .checkmark {
      width: 12px;
      height: 12px;
      top: 6px;
      left: 6px;
    }
  }

  .nav-tabs {
    margin-bottom: 0;
    border-bottom: none;
  }
  .nav-tabs .nav-item {
    width: 110px;
    font-size: 13px;
    letter-spacing: 0.15px;
    margin-bottom: -5px;
  }
  .nav-tabs .nav-link {
    padding: 10px 0 12px !important;
  }
  .emptyDashboard-plug {
    position: relative;
    left: -15px;
    height: calc(100vh - 370px);
    width: calc(100% + 30px);

    .emptyData {
      top: calc(50% + 15px);
    }
  }
  .emptyDashboard-plug:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }
  .analysis-equity .emptyDashboard-plug {
    left: 0;
    width: 100%;
    height: calc(100vh - 170px);
  }
  .analysis-equity .emptyDashboard-plug:before {
    top: 15px;
  }
  .analysis-equity .emptyDashboard-plug .emptyData {
    top: calc(50% + 8px);
  }

  .analysis-symbols .emptyDashboard-plug {
    width: 100%;
    height: calc(100vh - 200px);
  }

  .analysisSymbolsTable-titles {
    padding-left: 20px;
  }
  .analysis-symbols-data {
    width: fit-content;
    position: relative;
    padding: 20px;
  }
  .analysis-symbols-data .clipper, .analysis-symbols-data .scroller {
    max-width: inherit !important;
    width: fit-content !important;
  }
  .analysis-symbols-data:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }
  .tab-pane {
    padding: 15px;
  }
  .analysis-symbols .tab-pane {
    padding: 0;
  }

  .analysisDaysTable-data tbody tr:nth-child(odd) td {
    background: #f7f7fa;
  }
  .analysisDaysTable-data tbody tr:nth-child(even) td {
    background: #ededf3;
  }
  .analysisDaysTable-data tbody tr td {
    position: relative;
    font-family: 'Helvetica Neue Cyr Roman' !important;
    color: #3f464b;
  }
  .analysisDaysTable-data tbody tr td:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: solid 0.5px rgba(0, 0, 0, 0.1) !important;
  }
  .analysisDaysTable-data tbody tr .analysisDaysTable-dataDate {
    font-family: 'Montserrat', sans-serif !important;
    background: white !important;
    color: #353a3e;
  }
  .analysisDaysTable-data tbody tr .analysisDaysTable-dataDate:after {
    border: none !important;
  }
  .analysisDaysTable-data tbody tr:first-child td:nth-child(3),
  .analysisDaysTable-data tbody tr:first-child td:nth-child(3):after {
    border-radius: 6px 0 0 0;
  }
  .analysisDaysTable-data tbody tr:first-child td:last-child,
  .analysisDaysTable-data tbody tr:first-child td:last-child:after {
    border-radius: 0 6px 0 0;
  }
  .analysisDaysTable-data tbody tr:last-child td:nth-child(3),
  .analysisDaysTable-data tbody tr:last-child td:nth-child(3):after {
    border-radius: 0 0 0 6px;
  }
  .analysisDaysTable-data tbody tr:last-child td:last-child,
  .analysisDaysTable-data tbody tr:last-child td:last-child:after {
    border-radius: 0 0 6px 0;
  }
  .analysisSymbolsTable-data {
    table-layout: auto;
  }
  .analysisSymbolsTable-data tbody tr:nth-child(odd) td {
    background: #f7f7fa;
  }
  .analysisSymbolsTable-data tbody tr:nth-child(even) td {
    background: #ededf3;
  }
  .analysisSymbolsTable-data tbody tr td {
    position: relative;
    font-family: 'Helvetica Neue Cyr Roman' !important;
    color: #3f464b;
  }
  .analysisSymbolsTable-data tbody tr td:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: solid 0.5px rgba(0, 0, 0, 0.1) !important;
  }
  .analysisSymbolsTable-data tbody tr .analysisDaysTable-dataDate {
    width: 70px;
    font-family: 'Montserrat', sans-serif !important;
    background: white !important;
    color: #353a3e;
  }
  .analysisSymbolsTable-data tbody tr .analysisDaysTable-dataDate:after {
    border: none !important;
  }
  .analysisSymbolsTable-data tbody tr .analysisDaysTable-dataDate {
    padding-right: 10px;
  }
  .analysisSymbolsTable-data tbody tr:first-child td:nth-child(2),
  .analysisSymbolsTable-data tbody tr:first-child td:nth-child(2):after {
    border-radius: 6px 0 0 0;
  }
  .analysisSymbolsTable-data tbody tr:first-child td:last-child,
  .analysisSymbolsTable-data tbody tr:first-child td:last-child:after {
    border-radius: 0 6px 0 0;
  }
  .analysisSymbolsTable-data tbody tr:last-child td:nth-child(2),
  .analysisSymbolsTable-data tbody tr:last-child td:nth-child(2):after {
    border-radius: 0 0 0 6px;
  }
  .analysisSymbolsTable-data tbody tr:last-child td:last-child,
  .analysisSymbolsTable-data tbody tr:last-child td:last-child:after {
    border-radius: 0 0 6px 0;
  }

  .table thead td {
    padding: 0 0 20px;
    width: 70px;
    font-size: 12px;
    letter-spacing: 0.14px;
    font-weight: 500;
  }
  .table tbody td {
    padding: 9px 0;
    // width: 70px;
    // height: 38px;
    font-size: 12px;
    letter-spacing: 0.14px;
    font-weight: normal;
  }
  .table tbody td.num {
    font-weight: 600;
    color: white;
  }

  .analysisSymbolsTable-titles thead td {
    padding: 0;
    line-height: 37px;
  }

  .analysisSymbolsTable-data {
    padding-left: 20px;
  }

  .analysis-symbols .table table td {
    // width: 84px;
  }
  .analysis-symbols .table table td + td {
    white-space: nowrap;
  }

  .analysis-days .clipper,
  .analysis-days .scroller {
    position: static;
  }
  .analysis-days .track {
    top: 52px;
    right: 4px;
    bottom: 15px;
  }

  .analysis-symbols-data .clipper,
  .analysis-symbols-data .scroller {
    position: static;
    height: calc(100vh - 250px);
    max-height: none !important;
  }
  .analysis-symbols .tab-content {
    padding-bottom: 6px !important;
  }

  .tab-pane::-webkit-scrollbar {
    height: 6px;
  }
  .tab-pane::-webkit-scrollbar-track {
    -webkit-border-radius: 6px;
    border-radius: 6px;
  }
  .tab-pane::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 6px;
    background: rgba(46, 60, 71, 0.1);
  }
  .tab-pane::-webkit-scrollbar-button {
    width: 15px;
  }
}
