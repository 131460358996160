.newsNote {
  ul,
  ol {
    margin-left: 15px;
  }
}
.headerNewsNote {
  width: 20%;
}
.headerNewsNote p {
  text-decoration: underline;
}
.news-title {
  line-height: 24px;
  letter-spacing: 0.19px;
  font-size: 16px;
  font-weight: 600;
}
.newsPage .card-body {
  padding: 18px !important;
}
.newsPage .infinite-scroll-component {
  padding: 20px;
}
.newsPage .title {
  margin-bottom: 15px;
}
