.dailyFinres {
  .card-content {
    width: 1130px;
  }

  .accountFilter {
    width: auto;
    display: inline-block;
    margin-right: 20px;
    padding-left: 5px;
    padding-right: 0;
    vertical-align: top;
    height: 36px;
  }
  .dailyFinres-filterDateTitle {
    position: absolute;
    top: -2px;
    left: 0;
    font-size: 10px;
    letter-spacing: 0.12px;
    opacity: 0.6;
  }
  .boardsTabs-rightOptions .btn {
    height: 30px;
    padding: 0 !important;
    line-height: 28px;
    font-size: 14px;
    width: 100px;
    letter-spacing: 0.19px;
    font-weight: 600;
    margin-left: 30px;
  }
  .SingleDatePicker {
    width: 70px;
    vertical-align: bottom;
  }
  .SingleDatePickerInput {
    background-color: transparent;
    border: none;
  }
  .DateInput {
    background: transparent;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.14px;
    line-height: 20px;
    padding: 0;
    width: 70px;
    border-bottom: 1px solid #5672db;
    margin-right: 0 !important;
  }
  .DateInput_input {
    height: 20px;
  }
  .DateInput__display-text--focused {
    background: transparent;
    border-color: transparent;
    border-radius: 0;
    color: #484848;
  }

  .clipper,
  .scroller {
    position: relative;
    height: calc(100vh - 250px);
    max-height: none !important;
  }
  .scroller {
    position: relative;
    box-shadow: none;
    border-radius: 0 0 6px 6px;
  }
  .clipper:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    z-index: 5;
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }

  .nav-tabs {
    margin-bottom: 0;
    border-bottom: none;
    position: relative;
  }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    border: none;
    box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.05);
  }

  table {
    width: 100%;
  }
  table td {
    font-size: 12px;
    letter-spacing: 0.14px;
  }
  table thead td {
    font-weight: 500;
  }
  table tr:nth-child(2n) {
    background: #f7f8fc;
  }
  .table tbody + tbody {
    border: none;
  }
  .dailyFinres-boardTable > thead > tr > td,
  .dailyFinres-boardTable > tbody > tr > td {
    text-align: right;
  }
  .dailyFinres-boardTable > thead > tr.dailyFinres-tableTitles > td {
    position: relative;
    cursor: pointer;
    text-decoration-line: underline;
    text-decoration-style: dotted;
  }
  .dailyFinres-boardTable .oddStroke {
    background-color: white;
  }
  .dailyFinres-boardTable .evenStroke {
    background-color: #f7f8fc;
  }
  .dailyFinres-boardTable .oddStroke:hover,
  .dailyFinres-boardTable .evenStroke:hover {
    background-color: #f2f3fa;
  }

  .dailyFinresTable-plosch {
    width: 260px;
    padding-left: 200px;
  }
  .dailyFinresTable-prosadka {
    width: 120px;
  }
  .dailyFinresTable-marja {
    width: 100px;
  }
  .dailyFinresTable-sdelki {
    width: 120px;
  }
  .dailyFinresTable-commis {
    width: 120px;
  }
  .dailyFinresTable-finres {
    width: 260px;
    padding-right: 200px;
  }
  .dailyFinresTable-arrow {
    width: 1px;
    padding: 0;
    position: relative;

    span {
      position: absolute;
      left: -28px;
      top: 50%;
      margin-top: -8px;
    }
  }

  .dailyFinres-showMoreButton {
    cursor: pointer;
    width: 120px;
    display: flex;
    justify-content: center;
    margin: 40px auto 0;
    padding: 8px;
    border-radius: 4px;
    background: #5672db;
    color: white;
  }

  .dailyFinres .tableDeals-container .clipper, .dailyFinres .tableDeals-container .clipper .scroller {
    max-height: none !important;
  }

  .dailyFinres .clipper, .dailyFinres .scroller {
    height: 100% !important;
  }

  .tableDeals-container > td {
    padding: 0;
  }
  .tableDeals-container .clipper,
  .tableDeals-container .scroller {
    height: auto;
    max-height: 200px !important;
  }
  .tableDeals-container .scroller {
    width: 100%;
    max-width: none;
  }
  .tableDeals-container .scroller:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.05) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.05) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.05) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }
  .tableDeals {
    background-color: #f5f6fa;
  }
  .tableDealsFull td {
    width: 90px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .dailyFinresDealsTable-instr {
    padding-left: 18px !important;
  }
  .dailyFinresDealsTable-sekcia {
    width: 90px;
  }
  .dailyFinresDealsTable-timeOpen {
    width: 90px;
  }
  .dailyFinresDealsTable-costOpen {
    width: 90px;
  }
  .dailyFinresDealsTable-timeClose {
    width: 90px;
  }
  .dailyFinresDealsTable-costClose {
    width: 90px;
  }
  .dailyFinresDealsTable-operation {
    width: 90px;
  }
  .dailyFinresDealsTable-lots {
    width: 75px !important;
  }
  .dailyFinresDealsTable-maxLots {
    width: 90px;
  }
  .dailyFinresDealsTable-pribPunkt {
    width: 90px;
  }
  .dailyFinresDealsTable-pribMoney {
    width: 95px !important;
  }
  .dailyFinresDealsTable-comm {
    padding-right: 18px !important;
    width: 115px !important;
  }

  .emptyDashboard-plug {
    position: relative;
    height: calc(100vh - 250px);
    .emptyData {
      top: 50%;
    }
  }
  .emptyDashboard-plug:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }
}
