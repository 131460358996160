.anketaFormPage .popup {
    position: absolute;
}
.anketaFormPage .popup_inner {
    top: 10%;
    left: 50%;
    width: 50%;
    margin-left: 0;
    transform: translateX(-50%);
}

.anketaDocsGuide {
    top: 50%;
    background-color: #fff;

    &__body {
        .anketaDocsGuideBlockList {
            display: flex;
            margin-top: 40px;

            .anketaDocsGuideBlockListItem {
                flex: 1;

                &:nth-child(2) {
                    margin-left: 40px;
                }
            }
        }
    }
}
.anketaTypeChooseTitle {
    text-align: center;
    color: #2E3C47;
    margin-top: 20px;
    margin-bottom: 36px;
    line-height: 19.5px;
    font-size: 16px;
}
.anketaTypeChoose__wrapper {
    display: inline-block;
    text-align: left;

    .anketaTypeChoose {
        display: inline-flex;
        justify-content: center;

        .anketaType {
            position: relative;
            width: 200px;
            height: 254px;
            padding: 24px 20px;
            background: #F5F6FA;
            border-radius: 4px;
            box-sizing: border-box;
            text-align: left;

            &:first-child {
                margin-right: 32px;
            }

            .anketaTypeTitle {
                font-weight: 600;
                font-size: 16px;
                line-height: 19.5px;
                color: #424F59;

                &::after {
                    content: "";
                    position: absolute;
                    bottom: -20px;
                    left: 0;
                    width: 44px;
                    height: 3px;
                    background-color:#E5E9F7;
                }
            }

            .anketa-type-text {
                margin-top: 32px;
                font-size: 10px;
                line-height: 18px;
                font-weight: 400;
                color: #000;
            }

            .styled-checkbox {
                position: absolute; // take it out of document flow
                opacity: 0; // hide it
            
                & + label {
                    position: relative;
                    cursor: pointer;
                    padding: 0;
                }
            
                // Box.
                & + label:before {
                    content: '';
                    border: 1px solid #5672DB;
                    box-sizing: border-box;
                    border-radius: 27px;
                    margin-right: 6px;
                    display: inline-block;
                    vertical-align: text-top;
                    width: 16px;
                    height: 16px;
                    background: white;
                }
            
                // Box hover
                &:hover + label:before {
                    background: #5672DB;
                }
                
                // Box focus
                &:focus + label:before {
                    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
                }
            
                // Box checked
                &:checked + label:before {
                    background: #5672DB;
                    background-image: url("/images/ui/checkedIconAnketa.svg");
                    background-repeat: no-repeat;
                    background-position: center center;
                }
            }
        }
    }
}
.anketaFillingRules {
    margin-top: 16px;
    font-size: 10px;
    line-height: 12.19px;
    letter-spacing: 0.061875px;
    color: #000;

    &.crypto {
        width: 580px;
        margin: 0 auto;
        margin-top: 0;
        margin-bottom: 20px;
    }

    a {
        color: #5672DB;
        text-decoration: underline;
    }
}
.bankExtraData {
    margin-top: 30px;
    
    textarea {
        width: 100%;
        height: 104px;
        padding: 12px 16px;
        outline: none;
        resize: none;
        border: none;
        background-color: #F7F7FC;
        border-radius: 4px;
    }
}

@media (max-width: 500px) {
    .anketaFormPage .popup_inner {
        top: 0;
        left: 0;
        width: 100%;
        margin-left: 0;
        margin-top: 120px;
        transform: none;
    }
    .anketaDocsGuide {
        &__body {
            .anketaDocsGuideBlockList {
                flex-direction: column;
    
                .anketaDocsGuideBlockListItem {
                    flex: none;
    
                    &:nth-child(2) {
                        margin-left: 0;
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}