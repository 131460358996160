.rating .card-body {padding: 0;}
.rating .card-content {width: 450px;}

.ratingTitles {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 24px;
    padding-bottom: 0;
}
.ratingTitle {
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    opacity: 0.9;
    font-weight: bold;
}
.ratingHowBtn {
    color: #4E555F;
    font-size: 12px;
    line-height: 22px;
    background: #EDF2F5;
    border-radius: 6px;
    padding: 8px;
    cursor: pointer;
}

.rating .table td {font-size: 12px;}
.rating-tableTitles td {color: #91979B;}
.rating-tableTitles .ratingTableNum {
    width: 28px;
    box-sizing: content-box;
    text-align: center;
}
.rating .mainTable tr {border-bottom: 1px solid #F0F2F3;}
.rating .mainTable tr:last-child {border-bottom: none;}

.ratingTableNum span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    background-size: contain;
    background-repeat: no-repeat;
}
.rating-1, .rating-2, .rating-3 {font-weight: bold;}
.rating-1 .ratingTableNum span {background-image: url('/images/ui/rating-place1.png'); padding-top: 2px;}
.rating-2 .ratingTableNum span {background-image: url('/images/ui/rating-place2.png'); padding-top: 2px;}
.rating-3 .ratingTableNum span {background-image: url('/images/ui/rating-place3.png'); padding-top: 2px;}

.ratingTableNum {
    padding-left: 24px !important; 
    width: 28px;
    font-size: 11px;
    line-height: 14px;
}
.ratingTableAchievements {
    display: flex;                  
}
.ratingTableAchievements div {
    width: 16px; 
    height: 16px;
    margin-left: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 10px;
    border-width: 0.5px;
    border-style: solid;
}

.ratingTablePoints {width: 50px; box-sizing: content-box; text-align: right; padding-right: 24px !important}

.rating .clipper:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.05) 0%, transparent 100%);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.05) 0%, transparent 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.05) 0%, transparent 100%);
}
.rating .clipper,
.rating .scroller {
    height: calc(100vh - 200px);
    max-height: none !important;
}


.ratingHintContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
}
.ratingHint {
    position: fixed;
    right: -450px;
    top: 0;
    width: 450px;
    height: 100vh;
    background: #fff;
    box-shadow: -1px 2px 8px rgba(0, 0, 0, 0.12);
    transition: right 0.2s;
}
.isHinting .ratingHintContainer {display: block;}
.isHinting .ratingHint {right: 0;}

.ratingHint-closeBtn {
    padding: 4px;
    background: #F5F8FA;
    border-radius: 8px;
    display: inline-block;
    cursor: pointer;
}
.ratingHint-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    opacity: 0.9;
    margin-top: 20px;
}
.ratingHint ul {list-style: none;}
.ratingHint-item {
    margin-top: 14px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.ratingHint-item:last-child {margin-bottom: 0;}

.ratingHint-itemText {
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    width: calc(100% - 62px);
}
.ratingHint-itemNum {
    width: 32px;
    height: 32px;
    border-radius: 6px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
}
.ratingHint-itemNumPlus {background: #D3FBDC; color: #2C9042;}
.ratingHint-itemNumMinus {background: #FBDDD3; color: #D7623B;}

.rating-me {background: #cef0d8;}

.ratingHint .clipper,
.ratingHint .scroller {height: 100%;}
.ratingHint .scroller {padding: 12px;}