.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 10;
  overflow-y: auto;
  overflow-x: hidden;
}
.popup_inner {
  position: absolute;
  width: 470px;
  left: calc(50% + 135px);
  margin-left: -235px;
  background: white;
  padding: 26px 32px;
  border-radius: 4px;
  box-shadow: -3px 3px 21px 0 rgba(35, 68, 106, 0.21);
  // right: 25%;
  // top: 15%;
  // bottom: 25%;
  // margin: auto;
}
@media screen and (max-width: 992px) {
  .popup {
    position: absolute;
  }
  .popup_inner {
    margin-top: 0;
    margin-left: 0;
    width: auto;
    left: 0;
  }
}

.popup-crossCancel {
  cursor: pointer;
  position: absolute !important;
  top: 16px !important;
  right: 16px;
}
.popup-title {
  font-size: 16px;
  font-weight: 600;
  opacity: 0.8;
  letter-spacing: 0.08px;
}
.docPageSendMessage {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.08px;
  line-height: 25px;
}
.popupSubmitBtns {
  margin-top: 56px;

  &.cryptoSettings {
    .popupCancel {
      width: 100%;
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.19px;
      color: #fff;
      cursor: pointer;
      line-height: 28px;
      padding: 0 37px;
      text-align: center;
      background-color: #5672db;
      border-radius: 4px;
      border: none;

      &:disabled,
      &[disabled] {
        opacity: 0.6;
      }
    }
  }
}
.popupSubmitBtn {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.08px;
  cursor: pointer;
}
.popupSubmitBtn-ok,
.popupSubmitBtn-ok:hover {
  color: #5672db;
}
.popupCancel {
  font-size: 16px;
  font-weight: 500;
  opacity: 0.8;
  letter-spacing: 0.08px;
  cursor: pointer;
}

.radioBtn-container {
  margin-top: 18px;
  input {
    margin-top: 2px;
    margin-right: 10px;
  }
}
.react-datepicker__input-container input {
  padding: 7px !important;
}
.addressHandWriting {
  cursor: pointer;
}

.popupCreateDoc-inner::-webkit-scrollbar {
  display: block;
}

.modalCustomOverlay {
  z-index: 99999;
}
.modalCustomModal {
  padding: 0;
}
