.landingPage {
  .loginForm-logo {
    position: relative;
    height: 118px;
    width: 100%;
    margin-bottom: 20px;

    &.partner {
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }

    & img {
      width: 140px;
    }

  }
  input,
  input:focus {
    height: 26px;
    background: transparent;
  }
  input:-webkit-autofill,
  input:focus:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px rgb(255, 255, 255) inset;
  }
  .card-title {
    margin-bottom: 20px;
    opacity: 0.9;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.27px;
  }
  .SingleDatePicker_picker {
    top: -200px !important;
    left: calc(100% + 15px) !important;
  }
  .DateInput_input,
  .DateInput_input:focus {
    background: #f2f3fa !important;
  }
  // .SingleDatePicker {
  //     width: 100%;
  // }
  // .DateInput {
  //     line-height: 16px !important;
  //     width: 100% !important;
  //     padding: 5px !important;
  //     font-size: .8125rem !important;
  // }
  // .react-datepicker-wrapper,
  // .react-datepicker__input-container {width: 100%;}
  // .react-datepicker__input-container {
  //     input {
  //         width: 100%;
  //         padding: 8px;
  //         border: 1px solid #ddd;
  //         border-radius: 3px;
  //     }
  // }
  .slick-dots li button:before {
    display: none;
  }
  .popup {
    align-items: normal !important;
    background-color: transparent;

    .popup-crossCancel {
      color: #976ffa;
    }
    .popup-title {
      margin-bottom: 20px;
      opacity: 0.9;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0.27px;
    }
    .regPhoneField {
      width: 100%;
      height: 100%;
      padding: 8px;
      border: 1px solid #ddd;
      border-radius: 3px;
    }
    .btn-login {
      margin-top: 7px;
    }
  }
  .popup_inner {
    width: 280px;
    margin: 188px auto 0;
    top: 0;
    left: 0;
    border-radius: 6px !important;
    min-height: 346px;
    display: inline-block;
    text-align: left;
    position: relative;

    &.googleAuth {
      width: 400px;
      height: 300px;
    }
  }
  .landingPage-regSmsCode {
    position: absolute;
    top: calc(100% + 16px);
    left: 0;
    .popup-title {
      margin-bottom: 3px;
    }
    .popup-desc {
      font-size: 10px;
      letter-spacing: 0.05px;
      color: #8a9299;
      line-height: 14px;
    }
    .smsCode-container {
      margin: 18px 0 4px;
    }
    .reg-smsCodeField {
      width: 24px;
      height: 44px;
      margin: 0 6px;
      display: inline-block;
      text-align: center;
      padding-left: 0 !important;
      padding-right: 0 !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      border-bottom: 1px solid #2e3c47 !important;
      border-radius: 0;
      font-size: 32px;
      color: #8a9299;
      font-family: 'Open Sans', sans-serif !important;
      font-weight: 700;
    }
    .regSmsCode-options {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 6px;

      span {
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 0.13px;
        color: #8a9299;
      }
      .regSmsCode-optionsSendAgain {
        color: #5672db;
        cursor: pointer;
      }
    }
  }

  .reg-isAgreeContainer {
    .checkboxContainer {
      width: 18px;
      padding-left: 0px;
      display: inline-block;
      margin-bottom: 7px !important;
    }
    .checkmark {
      width: 12px;
      height: 12px;
    }
    .checkmark:after {
      left: 3px;
      top: 1px;
      width: 4px;
      height: 7px;
    }
    input {
      width: 0px;
    }
    .reg-agreeText {
      display: inline-block;
      font-size: 10px;
      font-weight: 300;
      width: 185px;
      vertical-align: top;
    }
  }

  & > .clipper,
  & > .clipper > .scroller {
    max-height: none;
    height: 100vh;
  }
  & > .clipper {
    background-image: linear-gradient(133deg, #6236ff, #f28ff4 168%);
  }
  & > .clipper > .scroller {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    // align-items: center;
    justify-content: center;
  }
  .loginForm {
    padding-top: 35px;
    width: 280px;
  }
  .card {
    position: relative;
    padding: 26px 32px;
    margin-bottom: 0;
    width: 280px;
    display: inline-block;
    top: 0;
    margin-top: 15px;
  }
  .btn-login {
    width: 100%;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.17px;
    margin-top: 60px;
    border-radius: 12px;
  }
  .login-options {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;

    span,
    a {
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 0.13px;
      color: #5672db;
      cursor: pointer;
    }
  }
}

.restorePage {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  // align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: linear-gradient(133deg, #6236ff, #f28ff4 168%);
}

.popup_inner-containerOut {
  width: 100%;
  text-align: center;

  // height: 100vh;
  // display: flex;
  // flex-flow: column;
  // align-items: stretch;
}
.popup_inner-container {
  // flex: 0 1 auto;
}
.reg-persData {
  display: inline-block;
  // flex: 0 1 auto;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;

  .landing-socials_fixed-docsLink a {
    color: #ccc !important;
    font-size: 10px;
  }
}
.reg-persData a {
  color: #ccc;
}
.reg-persData a:hover {
  color: #ccc;
}

.landing-socials {
  margin: 10px 0;

  a {
    display: inline-block;
    margin: 0 20px;
  }
}

.landing-socials_fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  margin: 10px 0;

  .landing-socials_fixed-docsLink a {
    color: #ccc !important;
    font-size: 10px;
  }
}

.customSelectListCountries {
  display: none;
}
.customSelectListCountries.isShowSelect {
  display: block;
}

.customSelectListCountriesCountainer {
  margin-bottom: 25px;
}
.customSelectListCountriesCountainer .customSelectedItem {
  line-height: 26px;
  padding: 0 0 0 5px;
  background: transparent;
  border-bottom: 1px solid #b9b7c7;
  border-radius: 0;
}
.customSelectListCountriesCountainer ul {
  box-shadow: -1px 1px 11px 0 rgba(35, 68, 106, 0.08),
    0 2px 5px 0 rgba(0, 0, 0, 0.08);
  background: white;
}
.customSelectListCountriesCountainer ul li {
  line-height: 26px;
  padding: 0 0 0 5px;
}

@media screen and (max-width: 992px) {
  .landingPage .SingleDatePicker_picker {
    top: 50% !important;
    position: fixed;
    left: 50% !important;
    margin-top: -150px;
    margin-left: -160px;
  }
}
