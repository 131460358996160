.bonusCreatePayment-sumField {
  background: white;
  padding: 5px 5px 5px 0;
  line-height: 15px;
  font-size: 12px;
  border-bottom: 1px solid #f2f3fa !important;
  outline: none;

  &_wallet {
    width: 250px;
  }

}

.bonusCreatePayment-sumDescr {
  margin-top: 3px;
  font-size: 10px;
  opacity: 0.6;
}
.uploader-title {
  font-size: 10px;
  letter-spacing: 0.12px;
  color: rgba(46, 60, 71, 0.6);
  margin-bottom: 4px;
  line-height: 12px;
}
.bonusProgram-container {
  position: relative;
  padding: 20px 30px;

  &+.expand {
    cursor: pointer;
    position: absolute;
    font-size: 50px;
    font-weight: bold;
    line-height: 0;
    transform: rotate(45deg);
    top: 30px;
    right: 40px;
  }

  &.expanded {
    &+.expand {
      transform: rotate(0deg); // translateY(55px);
    }
  }
  &.expanded.spend-bonuses {
    &+.expand {
      transform: rotate(0deg);
    }
  }

  & ol {
    padding-left: 16px;
  }

  & .bonusInfo {
    position: relative;
    padding-left: 25px;

    &:not(:first-child) {
      margin-top: 5px;
    }

    &::before {
      content: "";
      position: absolute;
      left: 9px;
      top: 5px;
      bottom: 0;
      height: 9px;
      width: 9px;
      background-image: url("/images/ui/bonus-marker.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  & .warnInfo {
    display: flex;
    align-items: center;
    margin-left: -10px;
    color: rgba(46, 60, 71, 0.6);
  }
}

.bonusId-section {
  margin-top: 60px;
  margin-bottom: 60px;

  .bonusId-addBtn {
    font-weight: 500;
    font-size: 14px;
    padding: 0 37px !important;
    background: #5672db;
    line-height: 28px;
  }

  & h4 {
    margin: 0;
    font-weight: bold;
    font-size: 20px;
  }

  & .table {
    background: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    & table {
      td.delete {
        width: 50px;
        text-align: center;
      }
      thead tr {
        background-color: #fff;
      }
      tbody tr:nth-child(2n) {
        background-color: #fff;
      }
      tbody tr:nth-child(2n + 1) {
        background-color: #f7f8fc;
      }
      tbody tr td.delete {
        cursor: pointer;
      }
    }
  }
}

.bonus-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 500px;

  &.dateBubble.error {
    input {
      border: 1px solid #f44336;
    }
  }
}
.bonus-backgrop {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.2);
}

.bonusesCreate {
  .scroller, .clipper {
    max-height: 70vh;
  }
  .uniform-uploader input[type='file'] {
    min-height: unset;
  }
}

@media (max-width: 500px) {
  .documentsMoving.spendBonuses {
    padding-top: 24px;
  }
}
