.orderBtn {
  cursor: pointer;
  text-decoration: underline;
  color: #008cba;
}
.closeBtn {
  cursor: pointer;
  background-color: transparent;
  border: none;
}
.disabledCloseBtn {
  color: gray;
  cursor: default;
}
.liveData-account {
  word-break: break-all;
}
