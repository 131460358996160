.dashboardEmptyPage {
  width: 1000px;
  margin: 0 auto;

  .dashboardEmptyPageBlockList {
    display: flex;

    &:nth-child(2) {
      margin-top: 50px;
    }

    .dashboardEmptyPageBlockItem {
      display: block;
      flex: 1;

      h2, p {
        margin-bottom: 15px;
      }

      &:not(:first-child) {
        margin-left: 20px;
      }

      border-radius: 10px;
      background-color: #fff;

      .dashboardEmptyPageBlockItemHeader {
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0px 15px;
        border-bottom: 1px solid #e8e8e8;
      }

      .dashboardEmptyPageBlockItemBody {
        padding: 25px 30px;
        color: initial;

        .dashboardEmptyPageBlockItemButtonsBlock {
          display: flex;
          margin-top: 35px;

          .dashboardEmptyPageBlockItemButtonsItem {
            display: flex;
            align-items: center;
            font-size: 14px;
            letter-spacing: .19px;
            color: #fff;
            cursor: pointer;
            line-height: 28px;
            padding: 0 18px;
            text-align: center;
            border-radius: 6px;
            border: none;
            min-width: 200px;
            height: 56px;

            &.downloads {
              background-color: #d5e0f6;

              .title {
                font-size: 16px;
                line-height: 18px;
                color: #1e62d6;
                font-weight: 700;
                margin-bottom: 0;
              }

              .subtitle {
                font-size: 14px;
                line-height: 14px;
                color: #808080;
              }

              svg {
                position: relative;
                top: 2px;
                height: 36px;
                fill: url(#linear-gradient);
              }

              img {
                height: 36px;
                margin-right: 20px;
              }
            }

            &.more {
              justify-content: center;
              margin-left: 20px;
              background-color: #5672db;
              color: #fff;
            }
          }
        }
      }

      &.terminal {
        width: 100%;
        background-image: linear-gradient(90deg, #acbaeb 0%, #abefe5 100%);
        padding-bottom: 40px;
        margin-bottom: 100px;

        .dashboardEmptyPageBlockItemHeader {
          border-color: rgba(64,64,64, 0.2);
        }

        .dashboardEmptyPageBlockItemBody {
          display: flex;

          .dashboardEmptyPageBlockItemBodyImg {
            flex: 1;
            position: relative;

            img {
              position: absolute;
              width: 100%;
              top: -50px;
            }
          }
        }

        .dashboardEmptyPageBlockItemBodyText {
          font-size: 12px;
          line-height: 15px;
          color: #000;

          & .semibold {
            font-weight: 600;
          }

          & ul {
            list-style: none;
            padding-left: 10px;
            margin-bottom: 10px;
      
            li {
              position: relative;
      
              &::before {
                content: "";
                position: absolute;
                left: -8px;
                top: 50%;
                transform: translateY(-50%);
                width: 2px;
                height: 2px;
                border-radius: 50%;
                background-color: #000;
              }
            }
          }
        }
      }
    }
  }
}

.dashboard-hidingTop {
  .tops-container {
    display: none !important;
  }
  .dashboard-title {
    display: none !important;
  }
  .clipper,
  .scroller {
    height: calc(100vh - 192px) !important;
  }
  .emptyDashboard-plug {
    height: calc(100vh - 127px) !important;
  }
}

.hello {
  text-align: center;
  width: 100%;
}

.dashboard {
  max-width: 1000px;
  margin: 0 auto;
  .tops-container {
    margin-bottom: 8px;
    padding: 0;
  }
  .top-card {
    flex: 1;
    width: 220px;
    font-size: 12px;

    h2 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 2px;
    }
    table td {
      padding: 7px 5px;
    }
    .topCard-ps {
      border-top: 1px solid #f2f3fa;
    }
    .topDashboard-index {
      width: 30px;
      padding-right: 0;
    }
    .topDashboard-rur {
      width: 97px;
      padding-right: 0;
    }
    .topDashboard-usd {
      padding-left: 22px;
    }
  }
  .card {
    margin-bottom: 0 !important;
  }

  .topCard-rightMargin {
    margin-right: 32px;
  }
  table {
    width: 100%;
    font-size: 12px;
  }
  .nav-tabs {
    margin-bottom: 0;
    border-bottom: none;
    position: relative;
  }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    border: none;
    box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.05);
  }
  .tab-content {
    background: white;
    padding: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
    padding: 0 !important;
    border-radius: 6px;
  }
  .tab-content .open-inst {
    background-color: #e6e8fa;
  }
  .boardsTabs {
    .boardsTabs-title {
      color: #2e3c47;
      font-weight: 500;
      margin-bottom: -2px;
    }
    .boardsTabs-sum {
      font-size: 13px;
    }
  }
  .nav-tabs .navItem-empty .nav-link {
    cursor: default;
  }
  .navItem-empty div {
    color: #2e3c47;
    opacity: 0.6;
  }
  .nav-tabs .nav-link {
    border: none;
    cursor: pointer;
    height: 100% !important;
    padding: 6px !important;
  }
  .nav-tabs .nav-link span {
    display: inline-block;
    min-height: 15px;
    margin-bottom: 2px;
  }
  .nav-tabs .nav-link.active {
    background-color: #fff !important;
    border-radius: 6px 6px 0 0;
  }
  .nav-tabs .nav-item {
    margin-bottom: -5px;
    width: 130px;
  }

  .closeDealsBtns {
    position: absolute;
    top: 10px;
    right: 0;
    .btn {
      margin-left: 20px;
      height: 30px;
      padding: 0 24px !important;
      font-size: 14px;
      font-weight: 600;
      font-family: 'Montserrat', sans-serif !important;
      letter-spacing: 0.2px;
    }
  }
  .dashboard-instCloseBtn {
    margin-left: 2px;
    cursor: pointer;
  }

  .tableLayoutFixes {
    table-layout: fixed;
  }
  .positionWithOrders {
    cursor: pointer;
  }
  .tableOrders-container {
    background-color: #f5f6fa;
  }
  .table thead td {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
    line-height: 16px;
    font-size: 12px;
    font-weight: 500 !important;
  }
  .table tbody .evenStroke,
  .table tbody .oddStroke {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    line-height: 16px;
    font-size: 12px;
  }
  .table > div > div > .clipper > .scroller > table > tbody > tr:hover,
  .openedOrders {
    background-color: #e6e8fa;
  }
  .table
    > div
    > div
    > .clipper
    > .scroller
    > table
    > tbody
    > tr.tableOrders-container:hover {
    background-color: #f5f6fa;
  }

  table tbody tr.evenStroke {
    background-color: #f2f3fa;
  }
  .openedOrders-arrow {
    min-width: 55px;
  }

  .tableOrders-container thead td,
  .tableOrders-container tbody td {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    line-height: 16px;
    font-size: 12px;
  }
  .tableOrders-container > td {
    padding: 0;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    line-height: 16px;
    font-size: 12px;
  }
  .tableOrders {
    width: 520px;
    margin: 8px auto;
    border: none;
  }
  .tableOrders td {
    text-align: right;
    width: 120px;
    padding: 6px 5px;
    text-transform: uppercase;
  }
  .tableOrders .closeOrder-icon {
    width: 28px;
  }

  .posPlosh {
    width: 157px;
  }
  .posUpdate {
    width: 140px;
  }
  .posInst {
    width: 163px;
  }
  .posPosit {
    width: 85px;
  }
  .posOrders {
    width: 73px;
  }
  .posFinres {
    width: 108px;
  }
  .posCommis {
    width: 100px;
  }
  .posDrawdown {
    width: 112px;
  }
  .posArrow {
    width: 62px;
  }

  .boardAllPositions {
    .posUpdate {
      width: 139px;
    }
    .posInst {
      width: 183px;
    }
    .posPosit {
      width: 95px;
    }
    .posOrders {
      width: 83px;
    }
    .posFinres {
      width: 108px;
    }
    .posCommis {
      width: 120px;
    }
    .posDrawdown {
      width: 120px;
    }
    .posArrow {
      width: 62px;
    }
  }

  .disabledCloseBtn {
    opacity: 0.6;
    cursor: default;
  }

  .emptyDashboard-plug {
    position: relative;
    height: calc(100vh - 338px);
    .emptyData {
      top: calc(50% + 25px);
    }
  }
  .emptyDashboard-plug:before {
    content: '';
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    height: 10px;
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }

  .clipper {
    height: calc(100vh - 390px);
    max-height: none !important;
  }
  .scroller {
    position: relative;
    box-shadow: none;
    border-radius: 0 0 6px 6px;
    height: calc(100vh - 390px);
    max-height: none !important;
  }
  .scroller:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }

  .tableOrders-container .track {
    right: 18px;
  }
  .tableOrders-container .clipper,
  .tableOrders-container .scroller {
    height: auto;
    max-height: 200px !important;
  }
  .tableOrders-container .scroller {
    border-radius: 0;
    position: relative;
    box-shadow: none;
  }
  .tableOrders-container .scroller:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }
  .tableOrders-container .scroller:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.05) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.05) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.05) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }
}

@media (max-width: 500px) {
  .dashboardEmptyPage {
    width: auto;
    padding: 0 20px;

    .dashboardEmptyPageBlockList {
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      margin-top: 60px;

      &:nth-child(2) {
        margin-top: 20px;
      }

      .dashboardEmptyPageBlockItem {
        &:not(:first-child) {
          margin-left: 0px;
          margin-top: 20px;
        }

        &.terminal {
          margin-bottom: 30px;

          .dashboardEmptyPageBlockItemBody {
            .dashboardEmptyPageBlockItemButtonsBlock {
              flex-direction: column;

              & > * {
                width: 100%;
              }

              .dashboardEmptyPageBlockItemButtonsItem {
                width: 100%;
                
                &.more {
                  margin-left: 0;
                  margin-top: 15px;
                }
              }
            }
            .dashboardEmptyPageBlockItemBodyImg {
              display: none;
            }
          }
        }
      }
    }
  }
  .dashboard {
    padding-left: 8px;
    padding-right: 8px;

    .tops-container {
      max-width: 100%;
      margin: 0 auto;
    }
    .top-card {
      flex: 1 0 84px;
      font-size: 12px;
  
      h2 {
        width: 60px;
      }
    }

    .btn.btn-success {
      height: 50px;
    }

    .dashboard-title {
      padding-left: 12px;;
      margin-bottom: 10px;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
    } 

    .dashboardTable > div {
      .dashboard-mobile-data-table {
        margin-top: 8px;
        background: #F9F9F9;
        border: 0.5px solid #EDF0F4;
        box-shadow: 0px 0px 10px rgba(35, 68, 106, 0.1);
        border-radius: 6px;
        padding: 12px 0px 4px;

        > table {
          td {
            position: relative;
            border-right: 1px solid #EDF0F4;
            padding: 0 18px 0 12px;         

            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              right: 0;
              left: 0;
              background-color: #EDF0F4;
              height: 1px;
            }


            &:nth-child(1) {
              width: 120px;
              padding: 0 0px 0 12px;
            }

            &:nth-child(2) {
              padding: 0 8px 0 12px;
            }

            &:nth-child(3) {
              padding: 0 2px 0 12px;
            }

            &:nth-child(4) {
              padding: 0 14px 0 12px;
            }

            &:first-child::after {
              left: 12px;
            }
    
            h4 {
              font-size: 12px;
              line-height: 14px;
              color: #2E3C47;
              margin-top: 4px;
              padding-bottom: 8px;
              margin-bottom: 0;
            }
    
            h5 {
              font-size: 9px;
              line-height: 11px;
              color: #8F8F8F;
              margin-bottom: 0;
            }

            .posFinres {
              display: flex;
              align-items: center;
              font-weight: bold;
            }

            .posFinres-circle {
              position: relative;
              bottom: 1px;
              width: 7px;
              height: 7px;
              margin-right: 5px;
              border-radius: 50%;
            }
            .posFinres-circle.green {
              background-color: #3BBE6F;
            }
            .posFinres-circle.red {
              background-color: #E55353;
            }
          }

          tr:nth-child(2) {
            td {
              h5 {
                margin-top: 8px;
              }
            }
          }

          td:nth-child(4) {
            border-right: none;
          }
        }
      }

      .dashboard-mobile-data-table .invisibleOrders {
        tr:nth-child(2) {
          td {
            &::after {
              display: none;
            }
          }
        }
      }

      .tableOrders-mobile {
        tr {
          margin-top: 11px;
        }
        thead {
          td {
            padding-top: 11px !important;
            font-size: 9px;
            line-height: 11px;
            color: #8F8F8F;
          }
        }
        tbody {
          td {
            font-size: 12px;
            line-height: 15px;
            color: #2E3C47;
          }
        }
        td {
          padding: 0 12px;
          padding-top: 10px !important;
          padding-bottom: 0 !important;
        }

        td:nth-child(2) {
          text-align: right;
        }
        td:nth-child(3) {
          text-align: center;
        }
        td:nth-child(5) {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
    }

    .boardsTabs-tab {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 12px;
      padding-right: 12px;
      color: #2E3C47;
      margin-top: 28px;
      margin-bottom: 4px;
      

      .boardsTabs-title {
        width: 45px;
        margin-bottom: 0;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
      }
      .boardsTabs-finres {
        display: flex;
        align-items: center;
        justify-content: center;

        .boardsTabs-circle {
          position: relative;
          bottom: 1px;
          width: 7px;
          height: 7px;
          margin-right: 5px;
          border-radius: 50%;
        }
        .boardsTabs-circle.green {
          background-color: #3BBE6F;
        }
        .boardsTabs-circle.red {
          background-color: #E55353;
        }
        .boardsTabs-sum {
          font-weight: bold;
          font-size: 12px;
          line-height: 14px;
        }
      }
    }

    .top-card {
      width: 89px;
      white-space: nowrap;

      .top_ru_currency {
        margin-top: 16px;
        font-size: 12px;
        line-height: 17px;
        color: #414141;
        font-feature-settings: 'pnum' on, 'lnum' on;

        &+div {
          font-feature-settings: 'pnum' on, 'lnum' on;
          color: #414141;
          opacity: 0.5;
        }
      }

      h2 {
        position: relative;
        font-size: 12px;
        margin-bottom: 0;
        margin-top: 0 !important;
        padding-bottom: 12px;

        &::after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          background-color:#EDF0F4;
          height: 1px;
        }
      }
    }
    .topCard-rightMargin {
      margin-right: 8px;
    }
    .card {
      padding: 12px 12px 16px 12px;
      background: #FFFFFF;
      box-shadow: 0px 0px 5px rgba(35, 68, 106, 0.05) !important;
      border-radius: 6px;
    }
  }
}