.logo-image {
  position: absolute;
  top: 0;
  left: 113px;

  path {
    fill: #ffffff;
  }
}
.logo-name {
  position: absolute;
  top: 72px;
  left: -88px;

  path {
    fill: #ffffff;
  }
}
.mainPageCrossCancel {
  color: #976ffa;
}

.switchContainer {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.switchContainer-labelLeft {
  padding-right: 42px;
}
.switchContainer-labelRight {
  padding-left: 42px;
}

.switch {
  // position: relative;
  // display: inline-block;
  // width: 38px;
  // height: 20px;
  // outline: none;
}
.switch input {
  position: absolute;
  top: -99999px;
  left: -99999px;
  display: none;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 7px;
  bottom: 0;
  background-color: #a6a6a6;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
  width: 20px;
  height: 8px;
}
.switchContainer-labelLeft .slider {
  left: auto;
  right: 0;
}
.switchContainer-labelRight .slider {
  left: 0;
  right: auto;
}
.slider:before {
  position: absolute;
  content: '';
  height: 12px;
  width: 12px;
  left: -2px;
  bottom: -2px;
  background-color: #606467;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
input:checked + .slider {
  background-color: #d6d6d6;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slider:before {
  background-color: #379f2e;
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}

/* DATE RANGE */
body {
  .DateRangePickerInput_arrow {
    display: none;
  }
  .SingleDatePicker {
    width: 100%;

    .SingleDatePickerInput {
      border: none;
      border-radius: 0;
    }
  }
  .DateInput {
    padding: 0;
    margin-right: 0 !important;
    width: 100%;
    background: transparent;
  }
  .DateInput_input {
    font-size: 12px;
    padding: 0;
    border-bottom: none;
    background: transparent;
  }
  .DateInput_input__disabled {
    font-style: normal;
    opacity: 0.4;
  }

  .dateUnderline {
    .DateInput_input {
      border-bottom: 1px solid #b9b7c7;
    }
    .DateInput_input__focused {
      border-bottom: 1px solid #5672db;
    }
  }
  .dateBubble {
    .SingleDatePickerInput {
      border-radius: 4px;
    }
    .DateInput_input {
      background: #f2f3fa;
      text-align: center;
      border-radius: 4px;
      border: solid 1px #b9b7c7;
    }
  }
  .dateBubbleInvalid {
    .SingleDatePickerInput {
      border: 1px solid #d06161;
    }
  }
}

.finresFilters-title {
  font-size: 13px;
}
.finresFilters-datePeriod div span {
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 8px;
  cursor: pointer;
}

/* CUSTOM SLIDER 'BARON' */

.clipper {
  position: relative;
  overflow: hidden;
  max-height: 45vh;
}
.scroller {
  overflow: auto;
  max-height: 45vh;
}
.scroller::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.track {
  display: none;
  position: absolute;
  top: 6px;
  right: 6px;
  bottom: 6px;
  width: 0;
}
.track._h {
  top: auto;
  width: auto;
  height: 0;
  left: 2px;
}
.baron > .track {
  display: block;
}
.bar {
  position: absolute;
  right: 0;
  width: 6px;
  border-radius: 3px;
  background: #2e3c47;
  opacity: 0.1;
}
.bar._h {
  bottom: 0;
  right: auto;
  width: auto;
  height: 6px;
}

/* CUSTOM SELECT */

.customSelect {
  position: relative;
  width: 100%;
  height: 100%;
  .customSelectedItem {
    width: 100%;
    height: 100%;
    background: #f2f3fa;
    padding: 0 16px;
    line-height: 36px;
    border-radius: 0.1875rem;
    cursor: pointer;
  }
  ul {
    position: absolute;
    list-style: none;
    top: calc(100% - 2px);
    border-top: 1px solid rgba(74, 74, 74, 0.1);
    left: 0;
    width: 100%;
    z-index: 4;
    background: #f2f3fa;
    border-radius: 0 0 0.1875rem 0.1875rem;
    li {
      cursor: pointer;
      padding: 0 16px;
      line-height: 36px;
      &:hover {
        background-color: #dddeed;
      }
    }
  }
  .toggle {
    position: absolute;
    z-index: 2;
    right: 6px;
    top: 7px;
    font-size: 20px;
    color: #2e3c47;
  }
}
.customSelectDisabled {opacity: 0.6;}
.customSelectSingleDatepicker {
  font-size: 12px;
  width: 90px;

  .customSelectedItem {
    border-bottom: 1px solid #5672db;
    background: transparent;
    line-height: 26px;
    height: 24px;
    border-radius: 0;
    padding: 0;
  }
  ul {
    top: 100%;
    box-shadow: -1px 1px 11px 0 rgba(35, 68, 106, 0.08),
      0 2px 5px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    border-top: none;

    .clipper,
    .scroller {
      height: 190px !important;
    }
    .track {
      top: 6px !important;
      bottom: 6px !important;
      right: 3px;
    }
    li {
      padding: 0;
      height: 24px;
      line-height: 24px;
    }
  }
}
.customSelectSingleDatepicker-year {
  width: 55px;
  margin-left: 12px;
}

/* INPUT UNDERLINE */

.inputUnderLine,
.inputUnderLine[readonly],
.inputUnderLine:focus,
.inputUnderLine[readonly]:focus {
  background-color: transparent !important;
  color: #2e3c47;
  padding: 5px 5px 5px 0 !important;
  border: none;
  border-bottom: 1px solid #b9b7c7;
  height: 30px;
  outline: none;
  font-size: 12px;
  letter-spacing: 0.06px;
}
.inputValidateWrong {
  border-bottom: 1px solid #d06161;
}
.inputValidateCorrect {
  border-bottom: 1px solid #5aad5f;
}
.inputValidateCurrentWrong {
  color: #d06161;
}

.inputUnderLine:focus {
  border-color: #5672db !important;
}

.inputUnderLine[readonly]:focus {
  border-color: #b9b7c7 !important;
}
.inputUnderLine[readonly]::placeholder,
.inputUnderLine[readonly] {
  color: #2e3c47;
  opacity: 0.6;
}

/* CUSTOM CHECKBOX */
/* Customize the label (the container) */
.checkboxContainer {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #2e3c47;
}

/* Hide the browser's default checkbox */
.checkboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #eee;
  border: 1px solid #5672db;
  border-radius: 2px;
}

/* When the checkbox is checked, add a blue background */
.checkboxContainer input:checked ~ .checkmark {
  background-color: #5672db;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkboxContainer input:checked ~ .checkmark:after,
.checkboxContainer input:checked ~ .checkmarkContainer .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkboxContainer .checkmark:after,
.checkboxContainer .checkmarkContainer .checkmark:after {
  left: 5px;
  top: 1px;
  width: 6px;
  height: 10px;
  border: 1px solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.dateInputValidateWrong {
  border: 1px solid #d06161 !important;
}

.uniform-uploader input[type='file'] {
  width: inherit !important;
  height: inherit !important;
  font-size: 0;
}
.btnUpload {
  background-color: #5672db;
  width: 130px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  border-radius: 4px;
  display: inline-block !important;
}
.btnUpload img {
  width: 14px;
  margin-left: 8px;
  margin-bottom: 1px;
}
.uploaderCheck {
  font-size: 18px;
  margin-left: 8px;
}
.uploaderSpinner {
  font-size: 18px;
  margin-left: 8px;
}

.btnLink {
  font-size: 14px !important;
  font-weight: 500 !important;
  width: 130px;
  padding: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center !important;
  height: 30px;
  line-height: 28px !important;
  letter-spacing: 0.19px;
  background-color: #5672db !important;
  color: white !important;
}
.btnLink img {
  width: 12px;
  margin-left: 16px;
  margin-bottom: 3px;
}
.btnBack,
.btnBack {
  background-color: transparent !important;
  border-color: #5672db !important;
  color: #5672db !important;
  box-shadow: none !important;
}

.formField-container {
  margin-bottom: 25px;

  input,
  input[readonly],
  input:focus,
  input[readonly]:focus {
    height: 30px;
    width: 100%;
  }
}

.emptyData {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  margin-top: -9px;
  text-align: center;
  font-size: 12px;
  color: #2e3c47;
  opacity: 0.4;
}

.isLoadingSpinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -13px;
  margin-left: -13px;
}

/* TABS */
.nav-tabs {
  margin-bottom: 0;
  border-bottom: none;
  position: relative;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: none;
  box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.05);
}
.tab-content {
  background: white;
  padding: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  padding: 0 !important;
  border-radius: 6px;
}
.tab-content .open-inst {
  background-color: #e6e8fa;
}
.boardsTabs {
  .boardsTabs-title {
    color: #2e3c47;
    font-weight: 500;
    margin-bottom: -2px;
  }
}
.nav-tabs .navItem-empty .nav-link {
  cursor: default;
}
.navItem-empty div {
  color: #2e3c47;
  opacity: 0.6;
}
.nav-tabs .nav-link {
  border: none;
  cursor: pointer;
  height: 100% !important;
  padding: 12px 0 14px !important;
  color: #2e3c47 !important;
}
.nav-tabs .nav-link.active {
  background-color: #fff !important;
  border-radius: 6px 6px 0 0;
}
.nav-tabs .emptyTab {
  opacity: 0.6;
  cursor: default;
}

.nav-tabs .nav-item {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: -5px;
  width: 140px;
}
.boardsTabs-rightOptions {
  position: absolute;
  top: 6px;
  right: 0;
}

/* Tooltip */
.customTooltip {
  position: absolute;
  right: 0;
  bottom: calc(100% + 5px);
  font-size: 10px;
  letter-spacing: 0.05px;
  padding: 7px 12px;
  border-radius: 6px;
  border: 1px solid #e0e2ee;
  background-color: #f7f8fa;
  max-width: 210px;
}

/* Custom radio button */
.customRadio:checked,
.customRadio:not(:checked) {
    position: absolute;
    left: -9999px;
}
.customRadio:checked + label,
.customRadio:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    padding-top: 0;
    font-size: 13px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #000;
}
.customRadio:checked + label:before,
.customRadio:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #9656fb;
    border-radius: 100%;
    background: #fff;
}
.customRadio:checked + label:after,
.customRadio:not(:checked) + label:after {
    content: '';
    width: 14px;
    height: 14px;
    background: #9656fb;
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.customRadio:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.customRadio:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}