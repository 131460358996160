// .content-broadcast {
//   padding-left: 80px;
//   padding-right: 80px;
// }
// .broadcasts {
//   .title {
//     margin-bottom: 8px;
//   }
//   .infinite-scroll-component {
//     padding: 20px;
//     margin-top: -12px;
//   }
//   .broadcastTagsFilter {
//     margin: 0 0 16px 0;
//     div {
//       display: inline-block;
//       padding: 0 8px 0 10px;
//       line-height: 24px;
//       margin-right: 10px;
//       border-radius: 12px;
//       color: white;
//       font-size: 10px;
//       cursor: pointer;
//       i {
//         margin-top: 1px;
//       }
//     }
//     .broadcastTagFilter {
//       background-color: #aaa;
//     }
//     .tag1 {
//       background-color: #5672db;
//     }
//     .tag2 {
//       background-color: #379f2e;
//     }
//     .tag3 {
//       background-color: #d06161;
//     }
//   }
//   .broadcast-container {
//     width: 300px;
//     margin-right: 32px;
//     float: left;
//     margin-bottom: 20px !important;
//   }
//   .broadcast-container .card-body {
//     padding: 9px 14px 14px;
//     height: 325px;
//   }
//   .broadcast-image {
//     position: relative;
//     height: 150px;
//     overflow: hidden;
//     background-position: center left;
//     background-size: 100% auto;
//     background-repeat: no-repeat;
//     background-color: white;
//     border-radius: 6px 6px 0 0;
//     box-shadow: -3px 3px 21px 0 rgba(137, 177, 223, 0.35) !important;
//   }
//   .broadcast-playIcon {
//     font-size: 34px;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     margin-left: -20px;
//     margin-top: -20px;
//     color: white;
//   }
//   .card {
//     border-radius: 0 0 6px 6px !important;
//     margin-bottom: 0 !important;
//   }
//   .broadcastTags {
//     font-size: 10px;
//     .broadcastTag {
//       color: #aaa;
//       cursor: pointer;
//       display: inline-block;
//       margin-right: 8px;
//     }
//     .tag1 {
//       color: #5672db;
//     }
//     .tag2 {
//       color: #379f2e;
//     }
//     .tag3 {
//       color: #d06161;
//     }
//   }
//   .broadcastContent {
//     height: 204px;
//     overflow: hidden;
//   }
//   .broadcastTitle {
//     font-size: 16px;
//     font-weight: bold;
//     letter-spacing: 0.2px;
//     line-height: 1.38;
//     color: #2e3c47;
//     margin-top: 8px;
//   }
//   .broadcastText {
//     font-size: 10px;
//     letter-spacing: 0.1px;
//     margin-top: 12px;
//     color: #2e3c47;
//     height: 150px;
//     overflow: hidden;
//   }
//   hr {
//     border-top-color: rgba(0, 0, 0, 0.1);
//     margin: 13px -16px;
//   }
//   .broadcastInfo-name {
//     font-size: 12px;
//     height: 18px;
//     font-weight: 500;
//     max-height: 18px;
//     overflow: hidden;
//   }
//   .broadcastInfo-date {
//     font-size: 10px;
//     font-weight: 500;
//   }
//   .broadcastInfo-link {
//     font-size: 10px;
//     height: 25px;
//   }
//   .broadcastInfo-linkText {
//     position: relative;
//     cursor: pointer;
//     color: #4586d2;
//     font-size: 10px;
//     font-weight: 500;
//     width: 160px;
//     white-space: nowrap;
//     overflow: hidden;
//   }
//   .broadcastInfo-linkText::before {
//     content: '';
//     position: absolute;
//     top: 0;
//     right: 0;
//     width: 30px;
//     height: 100%;
//     background: -moz-linear-gradient(
//       left,
//       rgba(255, 255, 255, 0) 0%,
//       rgba(255, 255, 255, 1) 100%
//     );
//     background: -webkit-linear-gradient(
//       left,
//       rgba(255, 255, 255, 0) 0%,
//       rgba(255, 255, 255, 1) 100%
//     );
//     background: linear-gradient(
//       to right,
//       rgba(255, 255, 255, 0) 0%,
//       rgba(255, 255, 255, 1) 100%
//     );
//   }
//   .broadcast-end {
//     color: #d06161;
//     font-size: 10px;
//     font-weight: 500;
//     height: 25px;
//   }
//   .broadcastInfo-link button {
//     color: #4586d2;
//     border: 1px solid #4586d2;
//     background: white;
//     padding: 5px 10px;
//     font-size: 10px;
//     border-radius: 4px;
//     cursor: pointer;
//   }
//   .broadcast-linkCopyBtn:hover {
//     background: white;
//     box-shadow: none !important;
//   }

//   .broadcastInfoBlock {
//     max-height: 72px;
//   }
//   .broadcasts-time {
//     font-size: 16px;
//     font-weight: bold;
//     color: #2196f3;
//   }
//   .broadcasts-name {
//     font-size: 16px;
//   }
//   .broadcasts-eventName {
//     font-weight: bold;
//     margin-bottom: 0;
//   }
//   .broadcasts-eventDescription {
//   }
//   .broadcasts-tag {
//     margin-right: 10px;
//   }
//   .btnCell {
//     width: 1px;
//   }
//   .spinner-container {
//     clear: left;
//   }
// }

.content {
  padding: 1.25rem 0 !important;
}

.broadcasts {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -30px;
  margin-left: -20px;
  margin-right: -20px;
  font-family: 'Helvetica Neue Cyr Roman' !important;
}
.broadcastsStreamsWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.broadcasts .clipper, .broadcasts .scroller {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  transition: 0.5s max-height ease;
}
.broadcasts .broadcastsStreamsWrapper, .broadcasts .broadcastsStreamsWrapper {
  width: calc(100% - 42px);
}
.broadcasts .h-68 .clipper, .broadcasts .h-68 .scroller {
  max-height: calc(100vh - 68px - 20px);
}
.broadcasts .h-128 .clipper, .broadcasts .h-128 .scroller {
  max-height: calc(100vh - 128px - 20px);
}
.broadcasts *:not([class^='icon-']):not([class*=' icon-']) {
  font-family: inherit !important;
}
.broadcasts-navLinksContainer {
  width: 1170px;
  position: relative;
  background: white;
  padding: 0 85px 0 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.broadcasts-navLinksContainer.schedulePage {
  justify-content: center;
}
.broadcasts-navLinksContainerBorder {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  z-index: 2;
  background: #DCE1E5;
}
.broadcasts-navLinks {
  display: flex;
  justify-content: center;
  align-items: center;
}
.broadcasts-navLinks a {
  display: flex;
  position: relative;
  height: 68px;
  line-height: 68px;
  padding: 0;
  margin-right: 75px;
  color: #242A2F;
  font-size: 18px;
  opacity: 0.9;
  border-bottom: 1px solid rgba(48, 119, 241, 0);
  font-weight: bold;
}
.broadcasts-navLinks a.active {
  position: relative;
  border-bottom: 1px solid #3077F1; 
  color: #1968EF;
  opacity: 0.9;
  z-index: 2;
}
.broadcasts-navLinks a:last-child {margin-right: 0;}
.sidebar-title {
  display: flex;
  align-items: center;
  line-height: normal;
}
.sidebar-newItems {
  width: 24px;
  height: 24px;
  background: #F5F8FA;
  border: 1px solid #C6C9D6;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3077F1;
  font-size: 13px;
  margin-left: 4px;
}


.broadcastsScheduleContainer {
  display: flex;
  justify-content: center;
  padding-top: 16.5px;
  max-width: 1170px;
  margin: 0 auto;
  color: #242A2F;
}
.broadcastsSchedule {
  border-radius: 6px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: stretch;
  list-style: none;
  margin-bottom: 0;
}
.broadcastsSchedule-day {
  margin-right: 28px;
  width: 180px;
}
.broadcastsSchedule-day:last-child {margin-right: 0;}
.broadcastsSchedule-dayName {
  text-align: center;
  font-size: 14px;
  line-height: 16.41px;
  margin-bottom: 12px;
  font-weight: bold;
}
.broadcastsSchedule-dayEventList {list-style: none;}
.broadcastsSchedule-dayEvent {
  margin-bottom: 12px;
  background: #FFFFFF;
  opacity: 0.9;
  border: 1px solid #EDEBE8;
  box-sizing: border-box;
  border-radius: 6px;
}
.broadcastsSchedule-dayEvent:last-child {margin-bottom: 0;}
.broadcastsSchedule-dayEventTime {  
  font-size: 13px;
  line-height: 15px;
  font-weight: bold;
}
.broadcastsSchedule-dayEventDesc {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  background: #EDF0F5;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  width: calc(100% + 2px);
  position: relative;
  left: -1px;
}
.broadcastsSchedule-dayEventTitle {
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 12px 12px 8px;
}
.broadcastsSchedule-dayEventAuthor {
  font-size: 12px;
  line-height: 16px;
}
.schedule-actual {
  display: inline-block;
  padding: 6px;
  margin-top: 20px; 
  font-size: 9px;
  background: #FFFFFF;
  border: 1px solid #E8EBED;
  border-radius: 6px;
  margin-left: auto;
}

.broadcast-filter {
  width: 36px;
  height: 36px;
  background: #F5F8FA;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
}


.broadcastsCoursesContainer {
  display: grid;
  grid-template-columns: 240px 240px 240px 240px;
  grid-gap: 16px;
  padding: 20px 85px 0 75px;
  width: 1170px;
  margin: 0 auto;
}
.broadcastsCourse {
  background: #fff;
  border: 0.5px solid #EDEBE8;
  border-radius: 6px;
  padding: 12px;
  width: 240px;
  height: 134px;
  box-sizing: border-box;
  opacity: 0.9;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: -1px 2px 7px rgba(0, 0, 0, 0);
  transition: opacity 0.2s, box-shadow 0.2s;
}
.broadcastsCourse:hover {opacity: 1; box-shadow: -1px 2px 7px rgba(0, 0, 0, 0.05);}
.broadcastsCourseInfo {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.broadcastsCourseInfo-text {width: calc(100% - 50px);}
.broadcastsCourseInfo-courseName, .webinar-title {font-size: 14px; line-height: 20px; font-weight: bold; color: #242A2F; max-height: 44px; overflow: hidden;}
.broadcastsCourseInfo-leadName, .webinar-host-name {font-size: 10px; line-height: 12px; color: #747474; margin-top: 4px;}
.broadcastsCourseInfo-avatar {
  width: 40px;
  height: 40px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
}
.broadcastsCourseBtn {
  cursor: pointer;
  background: #F5F8FA;
  border-radius: 6px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 36px;
  color: #242A2F;
}
.broadcastsCourseBtn svg {margin-right: 10px;}

.broadcastsIntern-header {
  background: #fff;
  padding: 15px 85px 15px 75px;
}
.broadcastsIntern-return {
  display: flex;
  justify-content: start;
  align-items: center;
}
.broadcastsIntern-returnBtn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  background: #F5F8FA;
  border: 1px solid #C6C9D6;
  border-radius: 6px;
  margin-right: 10px;
}
.broadcastsIntern-return span {font-size: 16px;}
.broadcastsInternList {padding: 18px 85px 0 75px; list-style: none;}
.broadcastsInternList li {
  font-size: 14px; 
  line-height: 14px; 
  margin-bottom: 24px;
  cursor: pointer;
  transition: color 0.2s;
}
.broadcastsInternList li a {
  color: #3A4044;
}
.broadcastsInternList li:hover a {color: #1465F2;}


.broadcastsFiltersContainer {
  width: 1170px;
  position: relative;
  background: #fff;
  padding: 12px 85px 12px 75px;
  transition: 0.5s margin ease;
}
.broadcastsFiltersContainer.closed {
  margin-top: -60px;
  z-index: -1;
}
.broadcastsMasterClassesContainer {
  width: 1170px;
  display: grid;
  grid-template-columns: 240px 240px 240px 240px;
  grid-gap: 16px;
  padding: 12px 85px 12px 75px;
}
.masterClass {
  cursor: pointer;
  background: #FFFFFF;
  box-shadow: -1px 2px 7px rgba(0, 0, 0, 0);
  border-radius: 6px;
  opacity: 0.9;
  transition: opacity 0.2s, box-shadow 0.2s;
  width: 240px;
  height: 126px;
  padding: 8px 8px 4px 8px;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  color: #242A2F;
}
.masterClass:hover {opacity: 1; box-shadow: -1px 2px 7px rgba(0, 0, 0, 0.05);}
.masterClass-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.masterClass-text {
  width: calc(100% - 50px);
  position: relative;
  top: 1px;
}
.masterClass-date {font-size: 10px; line-height: 12px; margin-bottom: 4px;}
.masterClass-avatar {
  width: 30px;
  height: 30px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
}
.masterClass-host-name {
  font-size: 14px; 
  line-height: 14px; 
  font-weight: bold;
}

.masterClass-tags {
  width: 100%;
  height: 72px;
  list-style: none; 
  margin-bottom: 0;
  margin-top: 12px;
}
.masterClass-tag {
  position: relative;
  display: inline-block;
  padding: 0px 8px;
  height: 20px;
  line-height: 20px;
  background: #F7F7FA;
  border-radius: 6px;
  font-size: 10px;
  color: #242A2F;
  margin-right: 4px;
  margin-bottom: 3px;  
}
.moreTagsList {
  color: #242A2F;
} 
.masterClass-tagNew {background: #1968EF; color: #FFFFFF;}
.masterClass-tagInSearch {color: #1968EF;}
.masterClass-tag-more {cursor: pointer;}
.moreTagsList {
  position: absolute;
  left: -1px;
  top: calc(100% - 6px);
  background: #FFFFFF;
  box-shadow: -1px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  padding: 6px 0;
  list-style: none;
  display: none;
}
.masterClass-tag-more:hover .moreTagsList {display: block;}
.moreTagsList li {
  padding: 0px 12px;
  height: 24px;
  line-height: 24px;
  font-size: 10px;
  text-align: left;
  white-space: nowrap;
}


.broadcastsFiltersContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.broadcastsFilters {
  display: flex;
  justify-content: center;
  align-items: center;
}
.broadcastsDiscard {
  display: flex;
  justify-content: center;
  align-items: center;
}
.broadcastsMasters-total {
  margin-right: 8px;
  font-size: 12px;
  line-height: 14px;
  color: #242A2F;
}
.broadcastsDiscardBtn {
  font-size: 12px;
  font-weight: bold;
  color: #929292;
  background: #F0F0F0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 106px;
  height: 36px;
  border-radius: 6px;
}
.broadcastsDiscardBtn.active {
  cursor: pointer;
  background: #F5F8FA;
  color: #1968EF;
}
.broadcastsDiscardBtn svg {margin-right: 8px;}

.broadcasts .customSelect label {opacity: 1;}
.broadcasts .customSelect {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 36px;
  margin-right: 12px;
  font-size: 12px;
}
.broadcasts .customSelect .customSelectDiscard {
  display: none;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-left: auto;
  border-radius: 4px;
}
.broadcasts .customSelect .customSelectDiscard.active {
  display: flex;
}
.broadcasts .customSelect .customSelectDiscard:hover {
  background: #E6EDF1;
}
.broadcasts .customSelect .customSelectedItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 10px;
  border-radius: 6px;
  background: #F5F8FA;
  border: 0.5px solid transparent;
  color: #5D6870;
}
.broadcasts .customSelect.customSelectActive .customSelectedItem {
  background: #F0F3F5;
  border: 0.5px solid #C6C9D6;
  color: #242A2F;
  display: flex;
  align-items: center;
}
.broadcasts .customSelect .customSelect-arrowDown,
.broadcasts .customSelect .customSelect-arrowUp {
  color: #64737E;
}
.broadcasts .customSelect.customSelectActive .customSelect-arrowDown,
.broadcasts .customSelect.customSelectActive .customSelect-arrowUp {
  color: #242A2F;
}
.broadcasts .customSelect .toggle {
  transform: scale(0.8);
  position: relative;
  right: 0;
  top: 1px;
}
.broadcasts .customSelect .customSelect-arrowDown {display: inline-block;}
.broadcasts .customSelect .customSelect-arrowUp {display: none;}
.broadcasts .customSelect.isShownList .customSelect-arrowDown {display: none;}
.broadcasts .customSelect.isShownList .customSelect-arrowUp {display: inline-block;}

.broadcasts .customSelect .customSelectList {display: none;}
.broadcasts .customSelect.isShownList .customSelectList {
  display: block;
  padding: 4px 0;
  top: calc(100% + 4px);
  border-top: 0;
  background: #fff;
  box-shadow: -1px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  max-height: 140px;
  min-height: 32px;
  overflow: hidden;
  width: initial;
  min-width: 100%;
}
.broadcasts .customSelect.isShownList .customSelectList .clipper,
.broadcasts .customSelect.isShownList .customSelectList .scroller {
  max-height: 138px;
}
.broadcasts .customSelect.isShownList .customSelectList li {
  display: flex;
  align-items: center;
  line-height: 18px;
  padding: 6px 10px;
  height: 32px;
}
.broadcasts .customSelect.isShownList .customSelectList li:hover {
  background-color: #F2F3FA;
}
.broadcasts .customSelect.isShownList .customSelectList li .checkboxContainer {padding-top: 2px;padding-left: 0;}
.broadcasts .customSelect.isShownList .customSelectList li .checkboxContainer .checkmarkContainer {
  display: flex;
  align-items: center;
}
.broadcasts .customSelect.isShownList .customSelectList li .checkboxContainer .checkmarkContainer .checkmark {
  position: static;
  background-color: #fff;
  border: 1px solid #1968EF;
  width: 12px;
  height: 12px;
}
.broadcasts .customSelect.isShownList .customSelectList li .checkboxContainer .checkmarkContainer .checkmark::after {
  left: 3.4px;
  top: 5.5px;
  width: 5px;
  height: 8px;
}
.broadcasts .customSelect.isShownList .customSelectList li .checkboxContainer .checkmarkContainer .checkmarkText {
  white-space: nowrap;
  font-size: 12px;
  margin-left: 6px;
}
.broadcasts .customSelect.isShownList .customSelectList li .checkboxContainer input:checked ~ .checkmarkContainer .checkmark {
  background-color: #1968EF;
}
.broadcasts .customSelect.isShownList .customSelectList li .checkboxContainer input:checked ~ .checkmarkContainer {
  color: #1968EF;
}

@media (max-width: 1280px) {
  .broadcastsSchedule-day {
    margin-right: 20px;
  }
}