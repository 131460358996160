.asUser-panel {
  position: fixed;
  width: 100%;
  left: 0;
  top: 50px;
  background: #999;
  padding: 15px;
  color: white;
  h4 {
    margin: 0;
  }
}
