/* *,
input {
    outline: 0!important
}

a,
input:focus {
    outline: 0
} */

/* .btn,
a:active,
a:focus,
a:hover {
    text-decoration: none
} */

.btn_landing {
  text-decoration: none;
}

/* .btn,
body,
html {
    font-size: 16px
} */

.btn_landing {
  font-size: 16px;
}

/* .link-arrow,
body {
    position: relative
} */

/* .footer-social,
.head-social,
.main-menu,
.mobile-menu,
.page-wrap ul {
    list-style: none
} */

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Light'), local('OpenSans-Light'),
    url(/../fonts/Open_Sans/opensanslight.woff2) format('woff2'),
    url(/../fonts/Open_Sans/opensanslight.woff) format('woff'),
    url(/../fonts/Open_Sans/opensanslight.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Light Italic'), local('OpenSansLight-Italic'),
    url(/../fonts/Open_Sans/opensanslightitalic.woff2) format('woff2'),
    url(/../fonts/Open_Sans/opensanslightitalic.woff) format('woff'),
    url(/../fonts/Open_Sans/opensanslightitalic.ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), local('OpenSans'),
    url(/../fonts/Open_Sans/opensans.woff2) format('woff2'),
    url(/../fonts/Open_Sans/opensans.woff) format('woff'),
    url(/../fonts/Open_Sans/opensans.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
    url(/../fonts/Open_Sans/opensansitalic.woff2) format('woff2'),
    url(/../fonts/Open_Sans/opensansitalic.woff) format('woff'),
    url(/../fonts/Open_Sans/opensansitalic.ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'),
    url(/../fonts/Open_Sans/opensanssemibold.woff2) format('woff2'),
    url(/../fonts/Open_Sans/opensanssemibold.woff) format('woff'),
    url(/../fonts/Open_Sans/opensanssemibold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Semibold Italic'), local('OpenSans-SemiboldItalic'),
    url(/../fonts/Open_Sans/opensanssemibolditalic.woff2) format('woff2'),
    url(/../fonts/Open_Sans/opensanssemibolditalic.woff) format('woff'),
    url(/../fonts/Open_Sans/opensanssemibolditalic.ttf) format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
    url(/../fonts/Open_Sans/opensansbold.woff2) format('woff2'),
    url(/../fonts/Open_Sans/opensansbold.woff) format('woff'),
    url(/../fonts/Open_Sans/opensansbold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
    url(/../fonts/Open_Sans/opensansbolditalic.woff2) format('woff2'),
    url(/../fonts/Open_Sans/opensansbolditalic.woff) format('woff'),
    url(/../fonts/Open_Sans/opensansbolditalic.ttf) format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Extrabold'), local('OpenSans-Extrabold'),
    url(/../fonts/Open_Sans/opensansextrabold.woff2) format('woff2'),
    url(/../fonts/Open_Sans/opensansextrabold.woff) format('woff'),
    url(/../fonts/Open_Sans/opensansextrabold.ttf) format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Extrabold Italic'), local('OpenSans-ExtraboldItalic'),
    url(/../fonts/Open_Sans/opensansextrabolditalic.woff2) format('woff2'),
    url(/../fonts/Open_Sans/opensansextrabolditalic.woff) format('woff'),
    url(/../fonts/Open_Sans/opensansextrabolditalic.ttf) format('truetype');
  font-weight: 800;
  font-style: italic;
}

.page-wrap:after,
.page-wrap:before {
  content: '';
  display: table;
}

.page-wrap:after {
  clear: both;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container,
.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

::-webkit-input-placeholder {
  opacity: 1;
}

:-moz-placeholder {
  opacity: 1;
}

::-moz-placeholder {
  opacity: 1;
}

:-ms-input-placeholder {
  opacity: 1;
}

a {
  text-decoration: underline;
  color: #3e607a;
}

/* a:focus,
a:hover {
    color: #254661
} */

/* a:active {
    color: #aeafaf
} */

.body {
  background: #fff;
  min-width: 320px;
  line-height: 1.4;
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: normal;
  color: #4c4d4d;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
}

.body.no-scroll {
  overflow: hidden;
}

.hidden {
  display: none !important;
  visibility: hidden !important;
}

@media screen and (max-width: 992px) {
  .hidden-laptop {
    display: none !important;
  }
}

@media screen and (min-width: 992px) {
  .visibility-laptop {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .hidden-tablets {
    display: none !important;
  }
}

/* @media screen and (min-width:767px) {
    .visibility-tablets {
        display: none!important
    }
}

@media (min-width:768px) {
    .container {
        width: 750px
    }
}

@media (min-width:992px) {
    .container {
        width: 970px
    }
}

@media (min-width:1200px) {
    .container {
        width: 1170px
    }
} */

.btn_landing,
.btn-conversely {
  font-weight: 700;
  padding: 8px 30px;
  line-height: 1.3;
  border: 2px solid #ee4a45;
  cursor: pointer;
}

.btn_landing {
  background: #ee4a45;
  display: inline-block;
  color: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.btn-conversely,
.btn_landing:hover {
  background: 0 0;
  color: #ee4a45;
}

.btn-conversely,
.link-arrow {
  font-size: 16px;
  display: inline-block;
  -webkit-transition: all 0.3s;
  text-decoration: none;
}

.btn-conversely {
  transition: all 0.3s;
}

.btn-conversely:hover {
  background: #ee4a45;
  color: #fff;
}

.link-arrow {
  color: #4c4d4d;
  font-weight: 300;
  padding-right: 30px;
  transition: all 0.3s;
}

.link-arrow .icon-arrow {
  position: absolute;
  fill: #4c4d4d;
  right: 0;
  top: 5px;
  width: 21px;
  height: 14px;
  background-size: contain;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.link-arrow:hover {
  color: #ee4a45;
}

.link-arrow:hover .icon-arrow {
  fill: #ee4a45;
}

.icon-social {
  fill: #3e607a;
}

.icon-social.social-youtube {
  width: 23px;
  height: 15px;
}

.icon-social.social-fb {
  width: 16px;
  height: 15px;
}

.icon-social.social-vk {
  width: 28px;
  height: 15px;
}

.icon-social.social-telegram {
  width: 19px;
  height: 16px;
}

.icon-social:hover {
  fill: transparent;
  stroke: #3e607a;
}

.section {
  padding: 55px 0;
}

.sec-title {
  font-size: 32px;
  font-weight: 700;
  padding-bottom: 24px;
  margin-bottom: 20px;
  line-height: 22px;
  position: relative;
}

.sec-title:before,
.toggle_menu:before {
  background: #3e607a;
  position: absolute;
  left: 0;
}

.sec-title:before {
  width: 70px;
  height: 2px;
  content: '';
  bottom: 0;
}

.sec-title h1,
.sec-title h2,
.sec-title h3,
.sec-title h4,
.sec-title h5,
.sec-title h6 {
  font-size: 32px;
  margin: 0;
}

.sec-title.title__white {
  color: #fff;
}

.form-checkbox .form-checkbox__label span,
.form-radio .form-radio__label span {
  color: #ff0101;
}

.sec-title.title__white:before {
  background: #fff;
}

.icon-bulb {
  background: url(/../images/Landing/bulb.svg) center no-repeat;
  background-size: contain;
  width: 57px;
  height: 66px;
  content: '';
  display: inline-block;
}

.icon-academic-cap,
.icon-case {
  height: 47px;
  display: inline-block;
  content: '';
}

.icon-academic-cap {
  background: url(/../images/Landing/academic-cap.svg) center no-repeat;
  background-size: contain;
  width: 87px;
}

.icon-case {
  background: url(/../images/Landing/briefcase.svg) center no-repeat;
  background-size: contain;
  width: 57px;
}

.toggle_menu {
  display: none;
  width: 28px;
  height: 22px;
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 1200px) {
  .toggle_menu {
    display: block;
  }
}

.toggle_menu:before {
  width: 100%;
  height: 3px;
  display: block;
  content: '';
  top: 0;
  opacity: 1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.toggle_menu span,
.toggle_menu:after {
  width: 100%;
  height: 3px;
  position: absolute;
  -webkit-transition: 0.5s;
  background: #3e607a;
  display: block;
  left: 0;
}

.toggle_menu:after {
  content: '';
  bottom: 2px;
  opacity: 1;
  transition: 0.5s;
}

.toggle_menu span {
  top: 9px;
  transition: 0.5s;
}

.toggle_menu.on:before {
  top: -20px;
  opacity: 0;
}

.toggle_menu.on:after {
  bottom: -20px;
  opacity: 0;
}

.toggle_menu.on span {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.toggle_menu.on span:nth-child(2) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.close_menu {
  display: block;
  width: 28px;
  height: 22px;
  position: relative;
  overflow: hidden;
}

.close_menu span {
  width: 100%;
  height: 2px;
  background: #3e607a;
  display: block;
  position: absolute;
  left: 0;
  top: 9px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.close_menu span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.close_menu span:nth-child(2) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.menu-overlay {
  position: fixed;
  background: rgba(62, 96, 122, 0.8);
  left: 0;
  top: 0;
  z-index: 45;
  width: 100%;
  height: 100vh;
  content: '';
  visibility: hidden;
  opacity: 0;
}

.menu-overlay.show {
  opacity: 1;
  visibility: visible;
}

.bread-crumbs {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
  word-wrap: break-word;
}

.bread-crumbs .bread-crumbs__link {
  display: inline-block;
  position: relative;
  padding-right: 10px;
  margin-right: 10px;
  text-decoration: none;
  margin-bottom: 5px;
}

.bread-crumbs .bread-crumbs__link:before {
  width: 10px;
  height: 4px;
  background: url(/../images/Landing/bread-crumbs__arrow.svg) center no-repeat;
  background-size: contain;
  content: '';
  position: absolute;
  right: -5px;
  top: 7px;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.bread-crumbs .bread-crumbs__current {
  display: inline;
}

.form-checkbox {
  display: inline-block;
}

.form-checkbox .form-checkbox__input {
  display: none;
}

.form-checkbox .form-checkbox__input:checked + .form-checkbox__label:after {
  opacity: 1;
}

.form-checkbox .form-checkbox__label {
  font-size: 16px;
  line-height: 17px;
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}

.form-checkbox .form-checkbox__label:after,
.form-checkbox .form-checkbox__label:before {
  position: absolute;
  width: 15px;
  height: 15px;
  top: 0;
  content: '';
}

.form-checkbox .form-checkbox__label:before {
  border: 1px solid #4c4d4d;
  left: 0;
  border-radius: 2px;
}

.form-checkbox .form-checkbox__label:after {
  background: url(/../images/Landing/check.png) center no-repeat;
  background-size: auto;
  left: 1px;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.form-radio {
  display: inline-block;
}

.form-radio .form-radio__input {
  display: none;
}

.form-radio .form-radio__input:checked + .form-radio__label:after {
  opacity: 1;
}

.form-radio .form-radio__label {
  font-size: 12px;
  line-height: 17px;
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}

.form-radio .form-radio__label:before {
  width: 15px;
  height: 15px;
  content: '';
  border: 1px solid #3e607a;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
}

.form-radio .form-radio__label:after {
  width: 5px;
  height: 5px;
  content: '';
  background: #3e607a;
  background-size: auto;
  position: absolute;
  left: 6px;
  top: 6px;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 50%;
}

.main-head {
  padding: 5px 0;
  border-bottom: 1px solid #e8e5e5;
}

.main-head .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
}

.main-head__logo {
  margin-right: 30px;
}

@media screen and (max-width: 550px) {
  .main-head {
    padding-bottom: 15px;
  }
  .main-head .container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .main-head__logo {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .main-head__menu {
    position: absolute;
    left: 15px;
    bottom: 3px;
  }
}

.main-head__logo .logo {
  display: block;
}

.main-head__right,
.main-menu {
  display: -webkit-box;
  display: -ms-flexbox;
}

.main-head__logo .logo img {
  width: auto;
  max-width: 210px;
  height: auto;
}

.main-head__menu {
  margin-right: 10px;
}

.main-head__menu .main-menu {
  margin-top: -5px;
}

.main-menu {
  display: flex;
}

@media screen and (max-width: 1200px) {
  .main-head__menu {
    margin-left: auto;
    margin-top: 0;
    margin-right: 30px;
  }
  .main-menu {
    display: none;
  }
}

.main-menu .main-menu__item {
  display: inline-block;
  padding: 0 15px;
  border-right: 1px solid #e2e7eb;
}

.main-menu .main-menu__item.curent-item .main-menu__link {
  color: #ee4a45;
}

.main-menu .main-menu__item:last-child {
  border-right: 0;
}

.main-menu .main-menu__item .main-menu__link {
  color: #4c4d4d;
  text-decoration: none;
  display: block;
  line-height: 1;
}

.main-menu .main-menu__item .main-menu__link:hover,
.mobile-menu .mobile-menu__item.curent-item .mobile-menu__link {
  color: #ee4a45;
}

.main-head__right {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 550px) {
  .main-head__right {
    margin-left: 25px;
  }
}

.head-authorization {
  margin-right: 25px;
}

.head-authorization .btn {
  font-size: 14px;
  font-weight: 300;
  padding: 5px 20px;
  border: 1px solid #ee4a45;
}

.head-social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.head-social .head-social__item {
  display: inline-block;
  margin-right: 10px;
}

.head-social .head-social__item:last-child {
  margin-right: 0;
}

.head-social .head-social__item .head-social__link {
  display: block;
  line-height: 0;
}

.mobile-menu .mobile-menu__item {
  display: block;
  margin-bottom: 7px;
  padding-bottom: 7px;
  border-bottom: 1px solid rgba(226, 231, 235, 0.4);
}

.mobile-menu .mobile-menu__item:last-child {
  border-bottom: 0;
}

.mobile-menu .mobile-menu__link {
  color: #3e607a;
  font-size: 16px;
  text-decoration: none;
  padding: 3px 0;
}

.mobile-menu .mobile-menu__link:hover {
  color: #ee4a45;
}

.mobile-pannel {
  background: #fff;
  width: 270px;
  padding: 50px 20px 20px;
  position: fixed;
  top: 0;
  height: 100vh;
  z-index: 50;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  left: -300px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.mobile-pannel.show {
  left: 0;
  opacity: 1;
  visibility: visible;
}

.mobile-pannel .close_menu {
  position: absolute;
  top: 10px;
  right: 20px;
}

.main-slider {
  position: relative;
}

.main-slider .main-slider__item {
  height: 480px;
  padding-top: 55px;
  overflow: hidden;
  color: #3d5f79;
  background: #ebebeb url(/../images/Landing/slider.jpg) no-repeat center;
}

.main-slider .main-slider__title {
  font-size: 36px;
  line-height: 41px;
  margin-bottom: 15px;
  font-weight: 700;
}

@media screen and (max-width: 420px) {
  .main-slider .main-slider__title {
    font-size: 24px;
    line-height: 28px;
  }
}

.main-slider .main-slider__desc {
  font-size: 16px;
  line-height: 18px;
  font-weight: 300;
  min-height: 115px;
  margin-bottom: 20px;
  max-height: 169px;
  overflow: hidden;
}

.main-slider .main-slider__desc p {
  margin-bottom: 10px;
}

.main-slider .main-slider__desc span {
  font-weight: 600;
}

.slick-dots {
  position: absolute;
  z-index: 5;
  width: 100%;
  left: 0;
  bottom: 20px;
  text-align: center;
}

.slick-dots li {
  display: inline-block;
  margin-right: 10px;
}

.slick-dots li:last-child {
  margin-right: 0;
}

.slick-dots li button {
  border: 1px solid #ee4a45;
  background: 0 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  font-size: 0;
  line-height: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}

.slick-dots li.slick-active button {
  background: #ee4a45;
}

.sec-about-company {
  overflow: hidden;
}

.sec-about-company .container {
  position: relative;
}

.sec-about-company .container:before {
  background: url(/../images/Landing/grid.png) center no-repeat;
  background-size: contain;
  content: '';
  width: 459px;
  height: 426px;
  position: absolute;
  right: -150px;
  top: -100px;
  z-index: 1;
}

.sec-about-company .about-company__content {
  max-width: 560px;
  margin-bottom: 40px;
  font-weight: 300;
  line-height: 23px;
  position: relative;
  z-index: 5;
}

.sec-about-company .about-company__content p {
  margin-bottom: 10px;
}

.sec-about-company .about-company__links {
  position: relative;
  z-index: 5;
}

.sec-about-company .about-company__links a.btn {
  margin-right: 35px;
  margin-bottom: 10px;
}

.sec-about-company .about-company__links .btn-conversely {
  min-width: 235px;
  text-align: center;
}

.sec-about-company .about-company__img {
  position: absolute;
  right: 40px;
  top: 80px;
  z-index: 2;
}

@media screen and (max-width: 1200px) {
  .sec-about-company .about-company__img {
    right: 0;
  }
}

@media screen and (max-width: 992px) {
  .sec-about-company .about-company__img {
    display: none;
  }
}

.sec-training {
  background: #3e607a;
  position: relative;
  overflow: hidden;
  padding: 60px 0 25px;
}

.sec-training:before {
  background: url(/../images/Landing/grid__white.png) center no-repeat;
  background-size: contain;
  content: '';
  width: 459px;
  height: 426px;
  position: absolute;
  left: 50%;
  top: 0;
  z-index: 1;
}

.sec-training .container {
  position: relative;
}

.sec-training .container:after,
.sec-training .container:before {
  position: absolute;
  top: 0;
  content: '';
}

.sec-training .container:before {
  background: url(/../images/Landing/grid__white.png) center no-repeat;
  background-size: contain;
  width: 459px;
  height: 426px;
  left: -150px;
  z-index: 1;
}

.sec-training .container:after {
  width: 687px;
  height: 344px;
  background: url(/../images/Landing/world-map.png) center no-repeat;
  background-size: contain;
  z-index: 2;
  right: -150px;
}

.sec-training .sec-title {
  position: relative;
  z-index: 5;
  margin-bottom: 55px;
}

.sec-training .training {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  z-index: 5;
}

.sec-training .training .training__item {
  background: #fff;
  width: calc(100% / 3 - 20px);
  margin-right: 30px;
  margin-bottom: 30px;
  padding: 30px;
  -webkit-box-shadow: 0 12px 10px 0 rgba(0, 0, 0, 0.32);
  box-shadow: 0 12px 10px 0 rgba(0, 0, 0, 0.32);
  text-align: center;
}

@media screen and (max-width: 992px) {
  .sec-training .training .training__item {
    width: calc(100% / 2 - 15px);
  }
}

@media screen and (max-width: 640px) {
  .sec-training .training .training__item {
    width: 100%;
    margin-right: 0;
  }
}

.sec-training .training .training__item:nth-child(3n + 3) {
  margin-right: 0;
}

@media screen and (max-width: 992px) {
  .sec-training .training .training__item:nth-child(3n + 3) {
    margin-right: 30px;
  }
  .sec-training .training .training__item:nth-child(2n + 2) {
    margin-right: 0;
  }
}

@media screen and (max-width: 640px) {
  .sec-training .training .training__item:nth-child(3n + 3) {
    margin-right: 0;
  }
}

.sec-training .training .training__item .training__item-icon {
  margin-bottom: 15px;
}

.sec-training .training .training__item .training__item-icon img {
  width: auto;
  max-width: 70px;
  height: auto;
  max-height: 30px;
}

.sec-training .training .training__item .training__item-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 10px;
}

.sec-training .training .training__item .training__item-desc {
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  margin-bottom: 25px;
}

.sec-training .training .training__item .training__item-link a {
  color: #4c4d4d;
  font-weight: 300;
  text-decoration: none;
  font-size: 16px;
  display: inline-block;
  position: relative;
  padding-right: 30px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.trading-item,
.trading-list {
  display: -webkit-box;
  display: -ms-flexbox;
}

.sec-training .training .training__item .training__item-link a .icon-arrow {
  position: absolute;
  fill: #4c4d4d;
  right: 0;
  top: 5px;
  width: 21px;
  height: 14px;
  background-size: contain;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.sec-training .training .training__item .training__item-link a:hover {
  color: #ee4a45;
}

.sec-training
  .training
  .training__item
  .training__item-link
  a:hover
  .icon-arrow {
  fill: #ee4a45;
}

.sec-trading .sec-title {
  margin-bottom: 35px;
}

.trading-list {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.trading-item {
  position: relative;
  border: 1px solid #4c4d4d;
  padding: 10px 30px;
  height: 135px;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: calc(100% / 3 - 20px);
  margin-right: 30px;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media screen and (max-width: 1200px) {
  .trading-item {
    padding: 10px 30px 10px 20px;
  }
}

@media screen and (max-width: 992px) {
  .trading-item {
    width: calc(100% / 2 - 15px);
  }
}

@media screen and (max-width: 640px) {
  .trading-item {
    width: calc(100% / 2 - 5px);
    padding: 10px 20px 10px 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 510px) {
  .trading-item {
    width: 100%;
    margin-right: 0;
  }
}

.trading-item:nth-child(3n + 3) {
  margin-right: 0;
}

@media screen and (max-width: 992px) {
  .trading-item:nth-child(3n + 3) {
    margin-right: 30px;
  }
}

@media screen and (max-width: 640px) {
  .trading-item:nth-child(3n + 3) {
    margin-right: 10px;
  }
}

@media screen and (max-width: 510px) {
  .trading-item:nth-child(3n + 3) {
    margin-right: 0;
  }
}

@media screen and (max-width: 992px) {
  .trading-item:nth-child(2n + 2) {
    margin-right: 0;
  }
}

.trading-item .trading-item__link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  z-index: 2;
}

.trading-item .trading-item__icon {
  width: 110px;
  margin-right: 25px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media screen and (max-width: 1200px) {
  .trading-item .trading-item__icon {
    width: 90px;
  }
}

@media screen and (max-width: 580px) {
  .trading-item .trading-item__icon {
    width: 60px;
    margin-right: 15px;
  }
}

.trading-item .trading-item__title {
  font-size: 24px;
  font-weight: 600;
  line-height: 22px;
  width: calc(100% - 135px);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media screen and (max-width: 1200px) {
  .trading-item .trading-item__title {
    width: calc(100% - 115px);
  }
}

@media screen and (max-width: 580px) {
  .trading-item .trading-item__title {
    width: calc(100% - 75px);
  }
}

.trading-item:hover {
  background: #ee4a45;
  border-color: #ee4a45;
}

.trading-item:hover .trading-item__title {
  color: #fff;
}

.trading-item:hover .trading-item__icon .icon-bulb {
  background: url(/../images/Landing/bulb__white.svg) center no-repeat;
  background-size: contain;
}

.trading-item:hover .trading-item__icon .icon-academic-cap {
  background: url(/../images/Landing/academic-cap__white.svg) center no-repeat;
  background-size: contain;
}

.trading-item:hover .trading-item__icon .icon-case {
  background: url(/../images/Landing/briefcase__white.svg) center no-repeat;
  background-size: contain;
}

.trading-bottom {
  padding-top: 20px;
}

.sec-provide {
  background: #ebebeb;
  padding-bottom: 25px;
}

.provide-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.provide-item {
  width: calc(100% / 4 - 22.5px);
  margin-right: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 70px;
}

@media screen and (max-width: 992px) {
  .provide-item {
    width: calc(100% / 2 - 15px);
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 510px) {
  .provide-item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.provide-item:nth-child(4n + 4) {
  margin-right: 0;
}

@media screen and (max-width: 992px) {
  .provide-item:nth-child(4n + 4) {
    margin-right: 30px;
  }
  .provide-item:nth-child(2n + 2) {
    margin-right: 0;
  }
}

@media screen and (max-width: 510px) {
  .provide-item:nth-child(4n + 4) {
    margin-right: 0;
  }
}

.provide-item .provide-item__icon {
  width: 70px;
  height: 70px;
  background: #fff;
  border: 1px solid #ee4a45;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 10px;
  overflow: hidden;
  border-radius: 50%;
}

.provide-item .provide-item__icon img {
  width: auto;
  max-width: 35px;
  height: auto;
  max-height: 35px;
}

.provide-item .provide-item__desc {
  width: calc(100% - 80px);
  font-size: 16px;
  line-height: 19px;
}

.sec-news {
  padding-top: 60px;
  padding-bottom: 40px;
}

.sec-news .sec-title {
  margin-bottom: 65px;
}

.news-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.news-item {
  width: calc(100% / 3 - 20px);
  margin-right: 30px;
  margin-bottom: 30px;
  min-height: 260px;
  position: relative;
  padding-bottom: 45px;
}

@media screen and (max-width: 992px) {
  .news-item {
    width: calc(100% / 3 - 10px);
    margin-right: 15px;
  }
}

@media screen and (max-width: 660px) {
  .news-item {
    width: calc(100% / 2 - 15px);
  }
}

@media screen and (max-width: 460px) {
  .news-item {
    width: 100%;
    margin-right: 0;
    min-height: auto;
  }
}

.news-item:nth-child(3n + 3) {
  margin-right: 0;
}

@media screen and (max-width: 660px) {
  .news-item:nth-child(3n + 3) {
    margin-right: 30px;
  }
  .news-item:nth-child(2n + 2) {
    margin-right: 0;
  }
}

@media screen and (max-width: 460px) {
  .news-item:nth-child(3n + 3) {
    margin-right: 0;
  }
}

.news-item .news-item__date {
  font-size: 15px;
  color: #999797;
  margin-bottom: 10px;
}

.news-item .news-item__title {
  font-weight: 700;
  color: #4c4d4d;
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 15px;
}

@media screen and (max-width: 992px) {
  .news-item .news-item__title {
    font-size: 16px;
    line-height: 20px;
  }
}

.news-item .news-item__title h1,
.news-item .news-item__title h2,
.news-item .news-item__title h3,
.news-item .news-item__title h4,
.news-item .news-item__title h5,
.news-item .news-item__title h6 {
  font-size: 20px;
  margin: 0;
}

@media screen and (max-width: 992px) {
  .news-item .news-item__title h1,
  .news-item .news-item__title h2,
  .news-item .news-item__title h3,
  .news-item .news-item__title h4,
  .news-item .news-item__title h5,
  .news-item .news-item__title h6 {
    font-size: 16px;
    line-height: 20px;
  }
}

.news-item .news-item__title a {
  color: #4c4d4d;
  text-decoration: none;
}

.accent-block,
.news-item .news-item__title a:hover {
  color: #3e607a;
}

.news-item .news-item__desc {
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
}

.news-item .news-item__link {
  position: absolute;
  left: 0;
  bottom: 10px;
}

.page-wrap {
  padding-top: 25px;
  padding-bottom: 60px;
  line-height: 23px;
  font-weight: 300;
}

.page-wrap h1,
.page-wrap h2,
.page-wrap h3,
.page-wrap h4 {
  font-weight: 700;
  margin-top: 20px;
}

.page-wrap img {
  width: auto;
  max-width: 100%;
  height: auto;
}

.page-wrap h1 {
  font-size: 36px;
  line-height: 41px;
  margin-bottom: 25px;
}

@media screen and (max-width: 480px) {
  .page-wrap h1 {
    font-size: 28px;
    line-height: 33px;
  }
}

.page-wrap h2 {
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 25px;
}

@media screen and (max-width: 480px) {
  .page-wrap h2 {
    font-size: 26px;
    line-height: 29px;
  }
}

.page-wrap h3 {
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 15px;
}

.page-wrap h4 {
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 10px;
}

.page-wrap h5,
.page-wrap h6 {
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 10px;
}

.page-wrap h5 {
  font-size: 18px;
  line-height: 23px;
}

.page-wrap h6 {
  font-size: 16px;
  line-height: 21px;
}

.page-wrap p {
  margin-bottom: 10px;
}

.page-wrap span {
  font-weight: 600;
}

.page-wrap strong,
table th {
  font-weight: 700;
}

.page-wrap ul {
  margin: 5px 0 20px;
}

.page-wrap ol li,
.page-wrap ul li {
  margin-bottom: 5px;
}

.page-wrap ul li {
  position: relative;
  padding-left: 20px;
}

.page-wrap ul li:before {
  width: 7px;
  height: 7px;
  background: #3e607a;
  content: '';
  position: absolute;
  left: 0;
  top: 8px;
  border-radius: 50%;
}

.page-wrap ol {
  list-style-position: inside;
}

.accent-block {
  padding: 10px 15px;
  background: #ebebeb;
  margin: 20px 0;
}

/* .main-footer,
table th {
    background: #3e607a;
    color: #fff
} */

.img-wrap {
  padding: 5px;
  border: 1px solid #ebebeb;
  display: inline-block;
  line-height: 0;
  margin: 0 25px 25px 0;
}

.float-left {
  float: left;
}

@media screen and (max-width: 520px) {
  .float-left {
    float: none;
  }
}

/* .table-wrap {
    overflow: auto
}

table {
    border-collapse: collapse;
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 16px
}

table th {
    padding: 15px 20px;
    border-right: 1px solid #5b788e
}

table th:last-child {
    border-right: 1px solid #3e607a
} */

/* table td {
    border: 1px solid #3e607a;
    padding: 15px 25px
} */

.main-footer {
  padding: 35px 0;
}

.main-footer .container {
  position: relative;
}

.footer-social {
  position: absolute;
  left: 15px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width: 460px) {
  .footer-social {
    position: static;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 15px;
  }
}

.footer-social .footer-social__item {
  display: inline-block;
  margin-right: 10px;
}

.footer-social .footer-social__item:last-child {
  margin-right: 0;
}

.footer-social .footer-social__item .footer-social__link {
  display: block;
  line-height: 0;
}

.footer-social .icon-social {
  fill: #fff;
}

.footer-social .icon-social:hover {
  fill: transparent;
  stroke: #fff;
}

.copyrighted {
  font-size: 14px;
  text-align: center;
}
